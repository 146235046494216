import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "topOfContentRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogContent = _resolveComponent("CatalogContent")!
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($props.catalogFiltersComponent), {
        "filters-list": $props.filtersList,
        parameters: $setup.parameters,
        "onUpdate:parameters": $setup.handleFilters,
        "onReset:parameters": $setup.resetAllFilters
      }, null, 40, ["filters-list", "parameters"]))
    ], 512),
    _createVNode(_component_LoadingWrapper, { loading: $setup.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_CatalogContent, {
          "url-without-page": $props.urlWithoutPage,
          loading: $setup.loading,
          items: $setup.result && $setup.result.data,
          pagination: $setup.pagination,
          "onUpdate:pagination": $setup.handlePagination,
          onScrollToTop: $setup.scrollToTop
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["url-without-page", "loading", "items", "pagination"])
      ]),
      _: 3
    }, 8, ["loading"])
  ], 64))
}