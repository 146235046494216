import { pipe } from 'ramda'
import {
  selectSlugFrom,
  brandsModelsFrom,
  multiselectSlugFrom,
  sameTransformer,
  simpleTransformer,
  checkEmpty,
  rangeFrom,
} from '@/utils/transformers'

const TRANSFORMERS_MAP = {
  select: pipe(selectSlugFrom, checkEmpty),
  multiselect: pipe(multiselectSlugFrom, checkEmpty),
  'brands-models': pipe(brandsModelsFrom, checkEmpty),
  range: pipe(sameTransformer, checkEmpty),
  'date-range': pipe(rangeFrom, checkEmpty),
  'input-range': pipe(sameTransformer, checkEmpty),
}

export function transform(filtersList, val) {
  return filtersList.reduce((acc, filter) => {
    if (val[filter.slug]) {
      const value = TRANSFORMERS_MAP?.[filter.widgetType]?.(
        JSON.parse(JSON.stringify(val[filter.slug]))
      )
      if (value) {
        if ('brands' === filter.slug) {
          acc = { ...acc, brands_models: value
          }
        } else {
          if (Array.isArray(value)) {
            acc[filter.slug] = value
          } else if (
            value instanceof Object &&
            (Object.hasOwn(value, 'min') || Object.hasOwn(value, 'max'))
          ) {
            acc[`${filter.slug}-min`] = value.min ? [value.min] : undefined
            acc[`${filter.slug}-max`] = value.max ? [value.max] : undefined
          } else {
            acc[filter.slug] = [value]
          }
        }
      }
    }
    return acc
  }, {})
}

export function prepare(transformed, mainAttributes: string[]) {

  return Object.keys(transformed).reduce((acc, key) => {
    if ('category' === key) {
      acc[key] = transformed[key]
      return acc
    }
    const values = transformed[key] ? Array.from(transformed[key]) : undefined
    if (values && values.length) {
      if (mainAttributes.includes(key)) {
        acc[key] = values
      } else {
        if (!acc['filters']) {
          acc['filters'] = {}
        }
        acc['filters'][key] = values
      }
    }
    return acc
  }, {})
}
