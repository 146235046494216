import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--space_none" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--6 sk-space_pr sk-space_pr--2" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--6 sk-space_pl sk-space_pl--2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ControlDatePicker = _resolveComponent("ControlDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_component_ControlDatePicker, {
        size: ['md'],
        styling: "accent",
        config: {...$setup.props.config, placeholder: _ctx.$t('from') },
        value: $setup.date.min,
        key: $setup.date.min,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ($setup.update($event, 'min', 'max'))),
        dateFormat: 'year',
        "value-format": "year",
        type: "year",
        editable: false,
        "default-value": $props.filter?.min ? new Date(`${$props.filter?.min}`) : null,
        disabledDate: $props.filter?.min ? $setup.allowDateInRange({ min: new Date(`${$props.filter.min}`), max: new Date(`${$props.filter.max}`) }) : undefined,
        disabledCalendarChanger: $props.filter?.min ? $setup.allowDateInRange({ min: new Date(`${$props.filter.min}`), max: new Date(`${$props.filter.max}`) } ): undefined
      }, {
        "icon-calendar": _withCtx(() => [
          _createVNode(_component_AppIcon, { name: "i-calendar" })
        ]),
        _: 1
      }, 8, ["config", "value", "default-value", "disabledDate", "disabledCalendarChanger"]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_component_ControlDatePicker, {
        size: ['md'],
        styling: "accent",
        config: {...$setup.props.config, placeholder: _ctx.$t('to') },
        value: $setup.date.max,
        key: $setup.date.max,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ($setup.update($event, 'max', 'min'))),
        dateFormat: 'year',
        "value-format": "year",
        type: "year",
        editable: false,
        "default-value": $props.filter?.max ? new Date(`${$props.filter?.max}`) : null,
        disabledDate: $props.filter?.max ? $setup.allowDateInRange({ min: new Date(`${$props.filter.min}`), max: new Date(`${$props.filter.max}`) }) : undefined,
        disabledCalendarChanger: $props.filter?.max ? $setup.allowDateInRange({ min: new Date(`${$props.filter.min}`), max: new Date(`${$props.filter.max}`) }) : undefined
      }, {
        "icon-calendar": _withCtx(() => [
          _createVNode(_component_AppIcon, { name: "i-calendar" })
        ]),
        _: 1
      }, 8, ["config", "value", "default-value", "disabledDate", "disabledCalendarChanger"]))
    ])
  ]))
}