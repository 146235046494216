import { createSender, createRemover, r } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'collections'

const SEARCH_QUERY = 'search'

const OFFERS_MODEL = `${MODEL}/offers`
const SAVED_SEARCH_MODEL = `${MODEL}/saved-search`

export const collectionsApi = {
  create: r('/create/', MODEL, createSender),
  delete: r('/delete/', MODEL, createSender),
  retrieve: r('/detail{/id}/', MODEL),
  update: r('/detail{/id}/', MODEL, createSender).config('method', 'PATCH'),
  duplicate: r('/duplicate/', MODEL, createSender),
  list: r(q('/list/', [SEARCH_QUERY]), MODEL),
  simpleList: r(q('/simple-list/', [SEARCH_QUERY]), MODEL),
  liked: {
    add: r('/liked/add/', MODEL, createSender),
    delete: r('/liked/delete/', MODEL, createSender),
  },
  offers: {
    add: r('/add/', OFFERS_MODEL, createSender),
    delete: r('/delete{/id}/', OFFERS_MODEL, createRemover).config(
      'method',
      'DELETE'
    ),
    list: r(q('/list{/collection}/', [SEARCH_QUERY]), OFFERS_MODEL),
  },
  savedSearch: {
    add: r('/add/', SAVED_SEARCH_MODEL, createSender),
    delete: r('/delete{/id}/', SAVED_SEARCH_MODEL, createRemover).config(
      'method',
      'DELETE'
    ),
    list: r(q('/list{/collection}/', [SEARCH_QUERY]), SAVED_SEARCH_MODEL),
  },
  view: r(q('/view{/collection}/', ['liked', 'sort']), MODEL),
  ownerView: r(q('/owner/view{/id}/', ['liked', 'sort']), MODEL),
}
