import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const CollectionsView = defineAsyncComponent(() => import('./CollectionsView.vue'))
const LikeToggler = defineAsyncComponent(() => import('./LikeToggler'))

export default {
  install(app: App<Element>): void {
    app
      .component('CollectionsView', CollectionsView)
      .component('LikeToggler', LikeToggler)
  }
}

