import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "blog-catalog-filters" }
const _hoisted_2 = { class: "g-row g-row--align_end g-row--justify_between g-row--appearance_spaced g-row--space_md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filtersList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["g-cell g-cols g-cols--12", $setup.classList[item.name]]),
          key: item.name
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.WIDGETS_MAP_BY_NAME[item.name].component), _mergeProps(item, {
            onInput: ($event: any) => (_ctx.$emit('update:parameters', { [item.name]: $event })),
            value: $props.parameters && $props.parameters[item.name]
          }), null, 16, ["onInput", "value"]))
        ], 2))
      }), 128))
    ])
  ]))
}