import { computed, ComputedRef, UnwrapNestedRefs, reactive, ref, Ref } from 'vue'
export const filelistToArray = (iterable: FileList | Iterable<File> | ArrayLike<File>): File[] => Array.prototype.slice.call(iterable);
// export function filesResolver(event: Event) {
//   let files = [];

//   if (event.dataTransfer.files.length > 0) {
//     files = toArray(event.dataTransfer.files);
//   } else if (event.dataTransfer.items) {
//     // FIXME
//     // Method `.webkitGetAsEntry` is returning other type of file, so
//     // it must be converted to a corresponding object type.
//     files = toArray(event.dataTransfer.items)
//       .map(item => item.webkitGetAsEntry());
//   }

//   return files;
// }

export interface ServerFile {
  id: number
  title: string
  description: string
  image: string
}

export class ProgressDescriptor {
  loaded: Ref<number>
  total: Ref<number>
  relative: Ref<number>
  constructor(loaded: number, total: number) {
    this.loaded = ref(loaded || 0);
    this.total = ref(total || 0.01);
    this.relative = computed(() => Math.min((this.loaded.value / this.total.value) || 0, 1));
  }
}

export class FileObject {
  file: File
  name: Ref<string>
  size: Ref<number>
  type: Ref<string>
  url: Ref<string | ArrayBuffer | null | undefined>

  constructor(file: File) {
    this.file = file;
    this.name = computed(() => this.file.name);
    this.size = computed(() => this.file.size);
    this.type = computed(() => this.file.type);
    this.url = ref(null);

    this.updateUrl(this.file);
  }

  updateUrl(file = this.file): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => this.url.value = e.target?.result;
    reader.readAsDataURL(file);
  }
}

export class FileDescriptor {
  id: string | number
  file: FileObject
  result: null | ServerFile
  status: string
  error: Error | null
  progress: UnwrapNestedRefs<ProgressDescriptor>
  constructor(file: FileObject, id: string, status: string) {
    this.id = id;
    this.file = file;
    this.result = null;
    this.status = status;
    this.error = null;
    this.progress = reactive(new ProgressDescriptor(0, 1));
  }
}

export default {
  ProgressDescriptor,
  FileObject,
  FileDescriptor,
}
