interface Field {
  name: string;
  label: string;
  rules: string;
  component?: any; 
  [key: string]: any; 
  isRequired?: boolean;
}

const REQUIRED_RULE = 'required'

export default function useField(
  name: string,
  label: string,
  { rules = 'required', component, ...rest }: Partial<Field> = {}
): Field {
  return { name, label, rules, component, isRequired: rules.includes(REQUIRED_RULE), ...rest };
}
