import { ref, nextTick, readonly, unref, type Ref } from 'vue'
import SnapSlider from '@tannerhodges/snap-slider'
import { useResizeObserver } from '@vueuse/core'

export function useSnapCarousel(
  container: Ref<HTMLElement | null>,
  options: {
    loop: boolean
    align: 'start' | 'center' | 'end'
    on?: Record<string, any>
  } = {
    loop: false,
    align: 'start',
  }
): {
  snapSlider: Ref<SnapSlider>
  isAvailable: Readonly<Ref<boolean>>
  isHasPrev: Readonly<Ref<boolean>>
  isHasNext: Readonly<Ref<boolean>>
  init: (option: Record<string, any>) => void
  checkAvailability: (node: Element) => void
  nextSlide: () => void
  prevSlide: () => void
  goToSlide: (i: number) => void
} {
  const snapSlider: Ref<typeof SnapSlider | undefined> = ref()
  const isAvailable = ref(false)
  const isHasPrev = ref(false)
  const isHasNext = ref(true)

  useResizeObserver(container, () => {
    checkAvailability(unref(container))
  })

  function init(opts = {}) {
    snapSlider.value = new SnapSlider(unref(container), {
      ...options,
      ...opts,
    })
  }
  function nextSlide() {
    if (snapSlider.value.scrolling) {
      return
    }
    snapSlider.value.goto('next')
    scrollHandler()
  }
  function prevSlide() {
    if (snapSlider.value.scrolling) {
      return
    }
    snapSlider.value.goto('prev')
    scrollHandler()
  }

  function goToSlide(index: number) {
    console.log(snapSlider.value, index);
    if (snapSlider.value.scrolling) {
      return
    }
    snapSlider.value.goto(Number(index))
    scrollHandler()
  }

  function scrollHandler() {
    function scrollendHandler(event) {
      checkAvailabilityButtons()
      container.value.removeEventListener('scrollend', scrollendHandler)
    }
    container.value.addEventListener('scrollend', scrollendHandler)
  }

  function checkAvailabilityButtons() {
    const { clientWidth, scrollWidth, scrollLeft, firstChild } =
      unref(container)
    const childWidth = (firstChild as HTMLDivElement).clientWidth
    isHasPrev.value = scrollLeft > 2
    isHasNext.value =
      scrollLeft + clientWidth - childWidth <= scrollWidth - childWidth - 65
  }

  function checkAvailability(node: Element) {
    const { clientWidth, scrollWidth } = node

    isAvailable.value = Boolean(scrollWidth - clientWidth > 2)
  }

  return {
    snapSlider: readonly(snapSlider),
    isAvailable: readonly(isAvailable),
    isHasPrev: readonly(isHasPrev),
    isHasNext: readonly(isHasNext),
    init,
    checkAvailability,
    nextSlide,
    prevSlide,
    goToSlide,
  }
}

export default {}
