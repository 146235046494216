import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "dummy-scroll-offers-catalog",
  ref: "topOfContentRef"
}
const _hoisted_2 = { class: "offers-catalog" }
const _hoisted_3 = { class: "offers-catalog__result" }
const _hoisted_4 = { class: "ds-caption ds-caption--appearance_bold ds-caption--size_2xs ds-caption--size_sm-lg ds-caption--size_md-xl ds-caption--appearance_lh_sm" }
const _hoisted_5 = {
  key: 0,
  class: "offers-catalog__btn"
}
const _hoisted_6 = { class: "offers-catalog__order" }
const _hoisted_7 = { class: "ds-caption ds-caption--appearance_medium ds-caption--size_2xs ds-caption--size_xs-lg ds-caption--size_sm-xl ds-caption--appearance_lg_sm sk-space_mr sk-space_mr--1" }
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "offers-catalog__view-switch" }
const _hoisted_11 = { class: "toggle-product-view" }
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filters_controller = _resolveComponent("filters-controller")!
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_CatalogContent = _resolveComponent("CatalogContent")!
  const _component_UserAccessState = _resolveComponent("UserAccessState")!
  const _component_loading_wrapper = _resolveComponent("loading-wrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_filters_controller, {
        class: "offers-catalog__sidebar",
        "filters-list": $props.filtersList,
        parameters: $setup.parameters,
        available: $setup.availableFilters,
        "onUpdate:parameters": $setup.handleFilters,
        "onReset:parameters": $setup.resetAllFilters,
        "onUpdate:checkedFilters": $setup.handleCheckedFilters,
        "onUpdate:availableFilters": $setup.handleAvailableFilters,
        category: $props.category,
        value: $setup.parameters,
        "token-string": $props.tokenString,
        "search-string": $setup.searchString.search
      }, null, 8, ["filters-list", "parameters", "available", "category", "value", "token-string", "search-string"]),
      _createVNode(_component_loading_wrapper, {
        class: "offers-catalog__main",
        loading: $setup.loading
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["offers-catalog__head", { 'offers-catalog__head--with_btn': $setup.isPermittedSimple([$setup.SAVED_SEARCH.SAVED_SEARCH.ADD]) }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Found")) + " " + _toDisplayString(_ctx.$pluralization({one: _ctx.$t("offer"), few: _ctx.$t("offers"), many: _ctx.$t("offers") }, $setup.pagination.totalResult || $setup.pagination.total || 0)), 1)
            ]),
            (!$props.hideSavedSearchBtn && $setup.isPermittedSimple([$setup.SAVED_SEARCH.SAVED_SEARCH.ADD]))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_ControlButton, {
                    type: "button",
                    styling: "primary",
                    view: "full",
                    "start-icon": "i-star",
                    "icon-size": ['lg'],
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.createSavedSearch())),
                    disabled: $setup.savedSearchLoadingState.loading.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Save to favorite")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("Price")), 1),
              _createElementVNode("button", {
                class: _normalizeClass(["round-button round-button--styling_accent round-button--size_sm", { 'is-active': $setup.OFFERS_SORT_MAP.up === $setup.sorting.sort }]),
                type: "button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleOrder($setup.OFFERS_SORT_MAP.up))),
                "aria-label": _ctx.$t('Price: High to low')
              }, [
                _createVNode(_component_AppIcon, {
                  name: "i-arrow-up",
                  "aria-hidden": "true",
                  size: "sm"
                })
              ], 10, _hoisted_8),
              _createElementVNode("button", {
                class: _normalizeClass(["round-button round-button--styling_accent round-button--size_sm", { 'is-active': $setup.OFFERS_SORT_MAP.down === $setup.sorting.sort }]),
                type: "button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.handleOrder($setup.OFFERS_SORT_MAP.down))),
                "aria-label": _ctx.$t('Price: Low to high')
              }, [
                _createVNode(_component_AppIcon, {
                  name: "i-arrow-down",
                  "aria-hidden": "true",
                  size: "sm"
                })
              ], 10, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: _normalizeClass(["toggle-product-view__el", { 'is-active': !$setup.storageOffersView}]),
                  type: "button",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.toggleWideViewProducts(false))),
                  "aria-label": _ctx.$t('Set compact view product')
                }, [
                  _createVNode(_component_AppIcon, {
                    name: "i-category",
                    "aria-hidden": "true",
                    size: "xl"
                  })
                ], 10, _hoisted_12),
                _createElementVNode("button", {
                  class: _normalizeClass(["toggle-product-view__el", { 'is-active': $setup.storageOffersView}]),
                  type: "button",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.toggleWideViewProducts(true))),
                  "aria-label": _ctx.$t('Set wide view product')
                }, [
                  _createVNode(_component_AppIcon, {
                    name: "i-category-wide",
                    "aria-hidden": "true",
                    size: "xl"
                  })
                ], 10, _hoisted_13)
              ])
            ])
          ], 2),
          _createVNode($setup["Chips"], {
            class: "offers-catalog__chips",
            all: $props.filtersList,
            filters: $setup.parameters,
            search: $setup.searchString.search,
            onResetSearch: $setup.resetSearch,
            onInput: $setup.resetAllFilters,
            "onUpdate:filters": $setup.handleFilters
          }, null, 8, ["all", "filters", "search"]),
          _createElementVNode("div", {
            class: _normalizeClass(["offers-catalog__content", { 'is-wide': $setup.storageOffersView }])
          }, [
            _createVNode(_component_CatalogContent, {
              "url-without-page": $props.urlWithoutPage,
              loading: $setup.loading,
              items: $setup.result && $setup.result.data,
              pagination: $setup.pagination,
              "onUpdate:pagination": $setup.handlePagination,
              onScrollToTop: $setup.scrollToTop
            }, {
              default: _withCtx((scope) => [
                _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
              ]),
              _: 3
            }, 8, ["url-without-page", "loading", "items", "pagination"]),
            _createVNode(_component_UserAccessState, {
              permissions: [$setup.IS_AGENT, $setup.IS_MANAGER]
            }, {
              default: _withCtx(({ hasAccess }) => [
                _renderSlot(_ctx.$slots, "append-content", {
                  canSaveSearch: hasAccess,
                  saveSearch: $setup.createSavedSearch,
                  isDisableButton: $setup.savedSearchLoadingState.loading.value
                })
              ]),
              _: 3
            }, 8, ["permissions"])
          ], 2)
        ]),
        _: 3
      }, 8, ["loading"])
    ])
  ], 64))
}