import { App } from 'vue'

import Validation from './validation/plugin'
import Auth from './auth'

export default {
  install(app: App<Element>): void {
    app
      .use(Validation)
      .use(Auth)
  }
}
