import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import DocumentUrlController from './DocumentUrlController'

import Catalog from './catalog'
import Blog from './blog'
import Vacancies from './vacancies'
import Favorites from './favorites'
import Search from './search'
import MainFilters from './mainFilters'
import Comparisons from './comparisons'
import Collections from './collections'
const FeaturedOffers = defineAsyncComponent(() => import('./FeaturedOffers.vue'))
const DynamicOffers = defineAsyncComponent(() => import('./DynamicOffers.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('FeaturedOffers', FeaturedOffers)
      .component('DynamicOffers', DynamicOffers)
      .component('DocumentUrlController', DocumentUrlController)
      .use(Catalog)
      .use(Blog)
      .use(Vacancies)
      .use(Favorites)
      .use(Search)
      .use(MainFilters)
      .use(Comparisons)
      .use(Collections)
  }
}

