import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

const PROFILE_PREFIX = 'cabinet:profile'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const PROFILE_VIEW = p('view')

const ProfileView = () => import('./views/ProfileView.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', ProfileView, PROFILE_VIEW, {
      meta: {
        title: () => $t('Manage your profile'),
      },
    }),
    
  ]
}
