import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DControlLabel = _resolveComponent("DControlLabel")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[$props.name], success: $props.isSuccess }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("VeeField"), _mergeProps(_ctx.$attrs, {
      name: $props.name,
      rules: $props.rules,
      id: $setup.id,
      modelValue: _ctx.$attrs.modelValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$attrs.modelValue) = $event))
    }), {
      default: _withCtx(({ field, handleInput }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.component), _mergeProps({ class: "d-control-descriptor__element" }, { ...$setup.getFP(field), ..._ctx.$attrs, ...$setup.props}, {
          labelBy: $props.labelBy,
          "set-value": $props.setValue,
          modelValue: _ctx.$attrs.modelValue,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$attrs.modelValue) = $event)),
            $setup.updateModelValue
          ],
          onUpdateModel: $setup.updateValue,
          "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => ($setup.isValid = $event))
        }), _createSlots({
          label: _withCtx(() => [
            _createVNode(_component_DControlLabel, _mergeProps({
              class: $setup.getLabelClass,
              "input-label": $setup.staticLabel ? $setup.staticLabel : $props.inputLabel,
              "is-required": $setup.isRequired
            }, _ctx.$attrs, { value: $setup.inputValue }), null, 16, ["class", "input-label", "is-required", "value"])
          ]),
          _: 2
        }, [
          _renderList($setup.eSlots, (name, key) => {
            return {
              name: name,
              fn: _withCtx((binded) => [
                _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
              ])
            }
          })
        ]), 1040, ["labelBy", "set-value", "modelValue"]))
      ]),
      _: 3
    }, 16, ["name", "rules", "id", "modelValue"])),
    _createVNode(_component_VeeError, { name: $props.name }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}