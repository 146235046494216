import { curry } from 'ramda'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import { baseResource } from '@/plugins/resource'

import {
  // defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons'

const filtersGetterBase = makeTemplateGetter('{;filters*}')
const filtersGetter = (parameters: string) =>
  filtersGetterBase(parameters).slice(1) || null

const partGetter = (name: string, prefix: string) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`)
  return (parameters: Record<string, unknown>) => {
    const result = base(parameters)
    return result === prefix ? null : result
  }
}

const pageGetter = partGetter('page', 'page')
const labelGetter = makeTemplateGetter('{label}')
const categoryGetter = makeTemplateGetter('{category}')
const getParametersGetter = makeTemplateGetter('{?limit,offset}')
const getSearchGetter = makeTemplateGetter('{?search}')
const getSearchPaginationGetter = makeTemplateGetter('{?search,limit,offset}')

const order_map = {
  label: labelGetter,
  category: categoryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
  search: getSearchGetter,
  search_pagination: getSearchPaginationGetter,
} as const

const initOrder = {
  path: ['prefix', 'filters'],
}

export const urlGetter = curry(
  (
    [prefix, postfix]: [string, string],
    {
      path,
      query = [],
    }: { path: (keyof typeof order_map)[]; query?: (keyof typeof order_map)[] },
    p: unknown
  ) => {
    const base = { prefix, postfix }
    const path_parts = path.reduce(
      (acc, x, i) => {
        acc[i] = order_map[x] ? order_map[x](p) : base[x as keyof typeof base]
        return acc
      },
      Array.from({ length: path.length }, () => null)
    )
    const query_params = query.reduce((acc, x) => {
      acc.push(order_map[x](p))
      return acc
    }, [] as string[])
    return (
      `${path_parts.filter((x) => x !== null).join('/')}/`
        .replace(/\/+/img, '/')
        .replace(/\/\//gim, '/')
        .replace(/\/\//gim, '/')
        .replace(/%2C/g, ',') + query_params.join('')
    )
  }
)

export const friendlyUrlGenerator = (
  url: string[],
  resource: typeof baseResource,
  order = initOrder
) => resource.url(urlGetter(url, order)).transform(jsonTransformer)
// .transform(defaultDataTransformer)
