interface Props {
  title: string;
  titleSlot: string
  disabled: boolean
  
}
export default function RsTab(
  props: Props,
  { slots }
) {
  return (
    <div class='rs-tabpanel' role='tabpanel'>
      {slots?.default?.()}
    </div>
  )
}
