import { baseResource } from '@/plugins/resource'
import { prefixLanguage } from '@/utils/urls'

import { friendlyUrlGenerator } from '@/packages/vue-friendly'

const MODEL = '/blog/'

const FILTERS_LIST_URL_POSTFIX = 'ajax/'

const order = {
  path: ['prefix', 'label', 'category', 'postfix', 'filters'],
  query: ['pagination_offset'],
}

export const ajaxBlogList = friendlyUrlGenerator(
  [prefixLanguage(MODEL), FILTERS_LIST_URL_POSTFIX],
  baseResource,
  order
)
