import {
  App,
  h,  
  SetupContext,
  VNode,
  defineAsyncComponent,
} from 'vue'

import Controller from '@/components/controls/descriptor.vue'
import InputLabel from '@/components/controls/label.vue'
import InputTextArea from '@/components/controls/textArea.vue'
import SimpleInput from '@/components/controls/simpleInput.vue'
import Multiselect from '@/components/controls/multiselect.vue'
import Recaptcha from '@/components/controls/recaptcha.vue'
// import Image from '@/components/controls/Image.vue'
import CButton from './RootElement.vue'
import CLabel from './CLabel.vue'
import Checker from './Checker'
import BaseSelect from './CSelect.vue'
import InputElement from './RootInput.vue'
// import PhoneInput from './PhoneInput.vue'
import CDescriptor from './CDescriptor.vue'
import { CDatePicker } from './BaseDatepicker'

const PhoneInput = defineAsyncComponent(() => import('./PhoneInput.vue'))
const AddressInput = defineAsyncComponent(() => import('./GoogleAddressAutocomplete.vue'))

const wrp =
  (
    tag: string,
    blockName: string,
    root: any = InputElement
    // root: typeof InputElement = InputElement
  ) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode => {
    return h(
      root,
      { ...props, ...ctx.attrs, innerElement: tag, blockName },
      ctx.slots
    )
  }

export const CSelect = wrp('vue-multiselect', 'control-select', BaseSelect)

export function hoc(WrappedComponent: any, params: Record<string, unknown>) {
  return h(WrappedComponent, {
    ...params,
  })
}

export const CCheckbox = hoc(Checker, { b: 'control-checkbox' })
export const CRadio = hoc(Checker, { b: 'control-radio' })
export const CInput = wrp('input', 'control-input')
export const CTextarea = wrp('textarea', 'control-textarea')
export const CPhone = wrp('phone-input', 'control-phone')


const dt =
  (tag: string, root = CDescriptor) =>
  (
    props: Record<string, unknown>,
    ctx: Pick<SetupContext, 'attrs' | 'slots' | 'emit'>
  ): VNode =>
    h(root, { ...props, ...ctx.attrs, component: tag }, ctx.slots)

const DSelect = dt('ControlSelect')
const DInput = dt('ControlInput')
const DTextarea = dt('ControlTextarea')
const DPhone = dt('ControlPhone')
const DAddressInput = dt('AddressInput')
const DDatePicker = dt('ControlDatePicker')

const install = (app: App<Element>): void => {
  const prefix = 'DControl'
  app
    .component(`${prefix}Input`, Controller)
    .component(`${prefix}Label`, InputLabel)
    .component(`${prefix}Textarea`, InputTextArea)
    .component(`${prefix}SimpleInput`, SimpleInput)
    .component(`${prefix}Multiselect`, Multiselect)
    .component(`${prefix}Recaptcha`, Recaptcha)
    // .component(`${prefix}Image`, Image)
    .component('PhoneInput', PhoneInput)
    .component('AddressInput', AddressInput)

    .component('ControlButton', CButton)
    .component('ControlLabel', CLabel)
    .component('ControlInput', CInput)
    .component('ControlTextarea', CTextarea)
    .component('ControlCheckbox', CCheckbox)
    .component('ControlRadio', CRadio)
    .component('ControlSelect', CSelect)
    .component('ControlPhone', CPhone)
    .component('ControlDatePicker', CDatePicker)

    .component('DInput', DInput)
    .component('DTextarea', DTextarea)
    .component('DSelect', DSelect)
    .component('DPhone', DPhone)
    .component('DAddressInput', DAddressInput)
    .component('DDatePicker', DDatePicker)
  }

export default {
  install,
}
