import { defineAsyncComponent, type App } from "vue";

import CatalogView from './CatalogView.vue'
import OwnerCatalogView from './OwnerCatalogView.vue'
import CatalogController from './list/CatalogController.vue'
import FiltersController from './filters/FiltersController.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('OffersCatalogView', CatalogView)
      .component('OffersOwnerCatalogView', OwnerCatalogView)
      .component('CatalogController', CatalogController)
      .component('FiltersController', FiltersController)
  }
}
