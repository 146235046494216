import { prefixRoutes } from '@/router/utils'
import { defineAsyncComponent } from 'vue'

const Receive = defineAsyncComponent(() => import('./Receive.vue'))

export const receiveRoute = (
  resource,
  children,
  component = Receive,
  type = '(\\d+)'
) => prefixRoutes(`:id${type}`, children, { component, props: { resource } })
