import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import { type Options } from '@/router'
import { i18n } from '@/i18n'
import { accessPermission } from '@/modules/user/permissions'
import { COLLECTIONS } from '@/modules/user/accessTokens'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import { collectionsApi } from '@/services/collections.service'
import Receive from '@m/cabinet/components/views/Receive.vue'

const COLLECTIONS_PREFIX = 'cabinet:collections'

const p = (name: string) => COLLECTIONS_PREFIX + ':' + name

export const COLLECTIONS_LIST = p('list')
export const COLLECTIONS_EDIT = p('edit')
export const COLLECTIONS_VIEW = p('view')

const List = () => import('./views/List/index.vue')
const ViewList = () => import('./views/ViewList.vue')
const Edit = () => import('./views/Edit.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, COLLECTIONS_LIST, {
      meta: {
        title: () => $t('My collections'),
        ...accessPermission([COLLECTIONS.COLLECTIONS.VIEW], options),
      },
    }),
    route(':id/list/', ViewList, COLLECTIONS_VIEW, {
      meta: {
        title: () => $t('My collections'),
        ...accessPermission([COLLECTIONS.COLLECTIONS.VIEW], options),
      },
    }),
    receiveRoute(
      collectionsApi.retrieve,
      [
        route('edit/', Edit, COLLECTIONS_EDIT, {
          // meta: accessPermission(APPLICATIONS_VIEW, options),
          meta: {
            title: (el) => {
              return $t('Edit collection') + ' #' + el.id
            },
            fallbackRouteName: COLLECTIONS_LIST,
          },
        }),
        route('view/', ViewList, COLLECTIONS_VIEW, {
          // meta: accessPermission(APPLICATIONS_VIEW, options),
          meta: {
            title: (el) => {
              return $t('View collection') + ' #' + el.id
            },
            fallbackRouteName: COLLECTIONS_LIST,
          },
        }),
        { path: '', redirect: { name: COLLECTIONS_EDIT } },
      ],
      Receive,
      ''
    ),
    { path: '', redirect: { name: COLLECTIONS_LIST } },
  ]
}
