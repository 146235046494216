import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "control-descriptor" }
const _hoisted_2 = { class: "control-descriptor__element blog-catalog-select" }
const _hoisted_3 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_4 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_5 = { class: "ds-inliner ds-inliner--size_lg" }
const _hoisted_6 = { class: "ds-inliner__body" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_8 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_9 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_10 = { class: "ds-inliner ds-inliner--size_xl" }
const _hoisted_11 = { class: "ds-inliner__body" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--auto ellipsis-content ellipsis-content--1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlLabel = _resolveComponent("ControlLabel")!
  const _component_ControlSelect = _resolveComponent("ControlSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ControlLabel, {
      "input-label": $props.title,
      for: $props.name
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.title), 1)
      ]),
      _: 1
    }, 8, ["input-label", "for"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ControlSelect, {
        name: $props.name,
        id: $props.name,
        "label-by": 'label',
        trackBy: 'value',
        size: ['lg'],
        options: $setup.options,
        modelValue: $setup.internal,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.internal) = $event)),
          $setup.input
        ],
        onClose: $setup.close,
        onOpen: $setup.open,
        "allow-empty": false
      }, {
        option: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_3, [
            (option?.code2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                      }, null, 2)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(" " + option.label), 1)
            ])
          ])
        ]),
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_8, [
            (option?.code2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                      }, null, 2)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("span", null, _toDisplayString(" " + option.label), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["name", "id", "options", "modelValue"])
    ])
  ]))
}