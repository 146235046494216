import { type RouteLocationNormalizedLoaded } from 'vue-router'

export function collectRoutePermissionCheckers(
  route: RouteLocationNormalizedLoaded
) {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta.accessConfig && r.meta.accessConfig.checker

    if (checker) {
      acc.push([checker, r])
    }

    return acc
  }, [])
}

export function routeProhibitionResolver(route) {
  const { resolver, next, redirect = false } = route.meta.accessConfig

  if (resolver) {
    return resolver(route) || false
  }

  return next || redirect.name
    ? {
        name: redirect.name,
        // Match the path of your current page and keep the same url...
        params: { pathMatch: route.path.split('/').slice(1).slice(0, -1) },
        // ...and the same query and hash.
        query: route.query,
        hash: route.hash,
      }
    : redirect || false
}

export function onHasRouteAccess(r, vm, parameters = {}) {
  const checkers = collectRoutePermissionCheckers(r)

  if (!(checkers && checkers.length)) {
    return Promise.resolve(true)
  }

  const executors = checkers.map(([checker, route]) =>
    checker
      .onHasAccess(vm, { route, ...parameters })
      .catch((e) => {
        console.warn(e)
        throw route
      })
      .then(() => true)
  )

  return Promise.all(executors)
}

export function resolveRouteNext(r, vm, parameters = {}) {
  return onHasRouteAccess(r, vm, parameters).catch(routeProhibitionResolver)
}

export const accessGuard =
  (parameters = {}) =>
  (to, from, next) =>
    resolveRouteNext(to, null, parameters).then((result) => {
      if (Array.isArray(result)) {
        const [res] = result
        return next(res)
      }
      return next(result)
    })

export const accessConfig = (checker, redirect = false, options = {}) => ({
  checker,
  redirect,
  ...options,
})

export function accessMeta() {
  return { accessConfig: accessConfig.apply(this, arguments) }
}
