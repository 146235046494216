import type { App } from 'vue'

import VueFinalModal from './x001-vue-final-modal'
import Recaptcha from './x002-vue-recaptcha'
import Countdown from './x003-vue-countdown'
import ResourceComponents from './resource'

export default {
  install(app: App<Element>): void {
    app
      .use(VueFinalModal)
      .use(Recaptcha)
      .use(Countdown)
      .use(ResourceComponents)
  }
}