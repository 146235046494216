import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import VacanciesCatalogView from './VacanciesCatalogView'
import VacancyResponseCreate from './VacancyResponseCreate.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('VacanciesCatalogView', VacanciesCatalogView)
      .component('VacancyResponseCreate', VacancyResponseCreate)
  }
}

