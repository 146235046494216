import type { App } from 'vue'
import { defineAsyncComponent, type DefineComponent } from 'vue'

import MainPageFilters from './MainPageFilters.vue'
import MainCategories from './MainCategories.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('MainCategories', MainCategories)
      .component('MainPageFilters', MainPageFilters)
  }
}

