import { Permission } from '@aspectus/permissions'
import { Pinia } from 'pinia'
import { isRef, unref } from 'vue'
import { ACCESS_TOKENS } from '../user/accessTokens'

export function waitForUser(
  state: unknown,
  getter: any,
  timeout = 5000,
  tick = 10
): Promise<void> {
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
      let check: ReturnType<typeof setTimeout> | null = null
      const rejector = () => (check && clearTimeout(check))
      //  || reject()
      const ticker = () => {
        let { user } = getter(state) || { user: {} }
        if (isRef(user)) {
          user = unref(user)
        }

        if (user.determined) {
          if (user.isAuthenticated()) {
            resolve(user)
          } else {
            reject()
          }
          return
        }
        check = setTimeout(ticker, tick)
      }
      setTimeout(rejector, timeout)
      ticker()
    // }, 200)
  })
}

export class UserCheckerPermissionBase extends Permission {
  getter: () => unknown
  timeout: number
  tick: number
  constructor({
    getter,
    timeout = 5000,
    tick = 5,
  }: { getter?: any; timeout?: number; tick?: number } = {}) {
    super()

    this.getter = getter
    this.timeout = timeout
    this.tick = tick
  }

  getStore(vm: { $pinia: Pinia }, { pinia }: { pinia?: Pinia } = {}): Pinia {
    return (vm && vm.$pinia) || pinia
  }

  waitForUser(
    vm: { $pinia: Pinia },
    args?: [{ pinia?: Pinia }?]
  ): Promise<void> {
    const store = this.getStore(vm, (args && args[0]) || {})
    return waitForUser(store, this.getter, this.timeout, this.tick)
  }

  checkUser(...arg: unknown[]): boolean {
    return false
  }
  onHasAccess(
    vm: { $pinia: Pinia },
    ...args: [{ pinia?: Pinia }]
  ): Promise<void> {
    return this.waitForUser(vm, args).then((user) => {
      if (!this.checkUser(user, vm, ...args)) {
        throw user
      }
    })
  }
}

export class UserCheckerPermission extends UserCheckerPermissionBase {
  checker: (...arg: unknown[]) => boolean
  constructor({
    checker,
    ...rest
  }: {
    checker: any
    [key: string]: unknown
  }) {
    super(rest)
    this.checker = checker
  }

  checkUser(...arg: unknown[]): boolean {
    return this.checker.apply(null, Array.from(arg))
  }
}

export function checkAuth(
  checker: any,
  config = {},
  Base = UserCheckerPermission
): UserCheckerPermission {
  return new Base(Object.assign({ checker }, config))
}

class SimplePermission extends Permission {
  constructor({
    checker,
    ...rest
  }: {
    checker: any
    [key: string]: unknown
  }) {
    super(rest)
    this.checker = checker
  }

  onHasAccess(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.checker()) {
        resolve()
      } else { 
        reject()
      }
    })
  }
}

export function simpleChecker(
  checker: any,
  Base = SimplePermission
  ) {
  return new Base({ checker })
  
}