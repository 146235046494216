import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_lg-mac g-row--space_xl-fhd g-row--align_center" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_3 = { class: "main-title main-title--variant_default" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12 sk-space_mb sk-space_mb--5" }
const _hoisted_5 = { class: "ds-caption" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_9 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_11 = { class: "ds-inliner ds-inliner--size_lg" }
const _hoisted_12 = { class: "ds-inliner__body" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--10" }
const _hoisted_14 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_15 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_16 = { class: "ds-inliner ds-inliner--size_xl" }
const _hoisted_17 = { class: "ds-inliner__body" }
const _hoisted_18 = { class: "g-cell g-cols g-cols--10 ellipsis-content ellipsis-content--1" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_20 = { class: "global-error" }
const _hoisted_21 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_22 = { class: "g-cell g-cols g-cols--12 g-cols--6-mac sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_23 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_24 = { class: "control-descriptor" }
const _hoisted_25 = {
  key: 0,
  class: "ui-dropzone__file"
}
const _hoisted_26 = { class: "ui-dropzone__text sk-space_ml sk-space_ml--2 sk-space_mr sk-space_mr--2" }
const _hoisted_27 = {
  key: 2,
  class: "file-upload-progress"
}
const _hoisted_28 = { class: "file-upload-progress__wrap" }
const _hoisted_29 = {
  key: 0,
  class: "global-error sk-space_mt sk-space_mt--2"
}
const _hoisted_30 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--1 sk-space_mt--2-xl"
}
const _hoisted_31 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_32 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_33 = { class: "ds-caption ds-caption--size_xs ds-caption--color_1 ds-caption--appearance_bold" }
const _hoisted_34 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_35 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "global-error" }
const _hoisted_38 = { class: "ds-caption ds-caption--size_xs ds-caption--appearance_bold ds-caption--color_accent ds-caption--appearance_lh_sm sk-space_mt sk-space_mt--2" }
const _hoisted_39 = {
  key: 1,
  class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--5"
}
const _hoisted_40 = { class: "global-error" }
const _hoisted_41 = { class: "g-cell g-cols g-cols--12 g-cols--6-lg sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }
const _hoisted_42 = { class: "g-row g-row--align_center g-row--appearance_spaced" }
const _hoisted_43 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_44 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_45 = { class: "ds-caption ds-caption--size_2xs ds-caption--color_1 ds-caption--appearance_medium ds-caption--appearance_start" }
const _hoisted_46 = ["href"]
const _hoisted_47 = ["href"]
const _hoisted_48 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_49 = { class: "global-error" }
const _hoisted_50 = { class: "g-cell g-cols g-cols--12 g-cols--6-lg sk-space_mt sk-space_mt--1 sk-space_mt--2-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_recaptcha = _resolveComponent("d-control-recaptcha")!
  const _component_DInput = _resolveComponent("DInput")!
  const _component_DSelect = _resolveComponent("DSelect")!
  const _component_DAddressInput = _resolveComponent("DAddressInput")!
  const _component_vee_error = _resolveComponent("vee-error")!
  const _component_DPhone = _resolveComponent("DPhone")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_DTextarea = _resolveComponent("DTextarea")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_VeeForm = _resolveComponent("VeeForm")!
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!

  return (_openBlock(), _createBlock(_component_LoadingWrapper, { loading: $setup.isLoad }, {
    default: _withCtx(() => [
      _createVNode(_component_VeeForm, {
        onSubmit: $setup.request,
        ref: "formRef"
      }, {
        default: _withCtx(({ errors, meta, validate, values, setErrors, setFieldError, isSubmitting }) => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$attrs.class)
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("How to apply")), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("Ready to join our team? Apply now by submitting your resume and a short cover letter explaining why you are ideal for this position at ESKA Finance.")), 1)
              ]),
              _createVNode(_component_d_control_recaptcha, {
                class: "control-descriptor--clear",
                modelValue: $setup.form.recaptcha,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.recaptcha) = $event)),
                ref: "captchaRef",
                onVerify: ($event: any) => ($setup.saveCaptcha($event, setErrors))
              }, null, 8, ["modelValue", "onVerify"]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_DInput, {
                  name: "firstName",
                  "input-label": $setup.labels.firstName,
                  size: ['md'],
                  modelValue: $setup.form.firstName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.firstName) = $event)),
                  rules: "required",
                  styling: "accent"
                }, null, 8, ["input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_DInput, {
                  name: "lastName",
                  "input-label": $setup.labels.lastName,
                  size: ['md'],
                  modelValue: $setup.form.lastName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.lastName) = $event)),
                  rules: "required",
                  styling: "accent"
                }, null, 8, ["input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_DSelect, {
                  rules: 'required',
                  styling: "accent",
                  name: "country",
                  id: "country",
                  "label-by": 'title',
                  "track-by": 'id',
                  "allow-empty": true,
                  "input-label": $setup.labels.country,
                  modelValue: $setup.form.country,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.country) = $event)),
                  "internal-search": false,
                  searchable: true,
                  loading: $setup.countriesLoading,
                  options: $setup.countriesList,
                  onSearchChange: e => $setup.countriesSearch(e),
                  onGetItems: $setup.countriesGet.bind(null),
                  onSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.result = null))
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                            }, null, 2)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", null, _toDisplayString(" " + option.title), 1)
                      ])
                    ])
                  ]),
                  singleLabel: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                            }, null, 2)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", null, _toDisplayString(" " + option.title), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["input-label", "modelValue", "loading", "options", "onSearchChange", "onGetItems"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_DAddressInput, {
                  rules: 'required',
                  styling: "accent",
                  name: "location",
                  id: "location",
                  country: $setup.form.country,
                  size: ['md'],
                  "input-label": $setup.labels.location,
                  "start-icon": "i-search",
                  "icon-size": ['lg'],
                  modelValue: $setup.form.location,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.location) = $event))
                }, null, 8, ["country", "input-label", "modelValue"]),
                _createVNode(_component_vee_error, {
                  class: "g-cell g-cols g-cols--12",
                  name: "location:nonFieldErrors",
                  as: "div"
                }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("div", _hoisted_20, _toDisplayString(message), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_DInput, {
                  name: "email",
                  id: "email",
                  type: "email",
                  size: ['md'],
                  rules: 'required|email',
                  styling: "accent",
                  "input-label": $setup.labels.email,
                  modelValue: $setup.form.email,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.email) = $event))
                }, null, 8, ["input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_22, [
                (_openBlock(), _createBlock(_component_DPhone, {
                  key: $setup.keyForReload,
                  name: "phone",
                  id: "phone",
                  size: ['md'],
                  "input-label": $setup.labels.phone,
                  "default-country-code": $setup.currentCountryCode,
                  rules: `required|realPhone:${$setup.form.phone}`,
                  modelValue: $setup.form.phone,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.form.phone) = $event)),
                  styling: "accent"
                }, null, 8, ["input-label", "default-country-code", "rules", "modelValue"]))
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_VeeField, {
                    as: "",
                    rules: "required",
                    id: "resume",
                    name: "resume",
                    "model-value": $setup.form.resume,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.form.resume) = $event))
                  }, {
                    default: _withCtx(({ field, errorMessage, meta }) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["ui-dropzone", { 'has-file': $setup.form.resume }]),
                        ref: "dropZoneRef"
                      }, [
                        ($setup.fileTemp)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createVNode(_component_AppIcon, { name: "i-check-fill" }),
                              _createElementVNode("span", _hoisted_26, _toDisplayString($setup.fileTemp.name), 1),
                              _createElementVNode("button", {
                                class: "ui-dropzone__remove",
                                type: "button",
                                onClick: $setup.clearFile
                              }, [
                                _createVNode(_component_AppIcon, { name: "i-close" })
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("p", {
                              key: 1,
                              class: "ui-dropzone__text",
                              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($setup.openFileDialog && $setup.openFileDialog(...args)))
                            }, _toDisplayString($setup.isOverDropZone ? _ctx.$t("Drop file here") : _ctx.$t("Select a resume/portfolio or drag and drop them here*")), 1)),
                        ($setup.fileTemp?.progress?.relative && !$setup.form.resume)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("div", {
                                  class: "file-upload-progress__fill",
                                  style: _normalizeStyle(`width: ${$setup.fileTemp.progress.relative * 100}%;`)
                                }, null, 4)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2),
                      errorMessage
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["model-value"])
                ])
              ]),
              (!$setup.props.vacancy)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createVNode(_component_DInput, {
                      name: "vacancy",
                      id: "vacancy",
                      type: "vacancy",
                      size: ['md'],
                      rules: 'required',
                      styling: "accent",
                      "input-label": $setup.labels.vacancy,
                      modelValue: $setup.form.vacancy,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.form.vacancy) = $event))
                    }, null, 8, ["input-label", "modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_DTextarea, {
                  class: "is-textarea",
                  name: "message",
                  size: ['md'],
                  "input-label": $setup.labels.message,
                  modelValue: $setup.form.message,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.form.message) = $event)),
                  rows: "5",
                  rules: "max:1000",
                  styling: "accent"
                }, null, 8, ["input-label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t("Messengers/Social networks")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.form.socialLinks, (social, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${index}`,
                    class: "g-row g-row--space_md g-row--align_center g-row--appearance_spaced"
                  }, [
                    _createElementVNode("div", _hoisted_34, [
                      (_openBlock(), _createBlock(_component_DInput, {
                        name: `socialLinks:${index}`,
                        id: `socialLinks:${index}`,
                        key: `socialLinks_${index}_${social.orderModal || 1}`,
                        size: ['md', 'md-xl'],
                        rules: 'url',
                        modelValue: $setup.form.socialLinks[index].value,
                        "onUpdate:modelValue": ($event: any) => (($setup.form.socialLinks[index].value) = $event),
                        styling: "accent"
                      }, null, 8, ["name", "id", "modelValue", "onUpdate:modelValue"]))
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("button", _mergeProps({
                        class: "round-button round-button--styling_secondary",
                        type: "button",
                        onClick: ($event: any) => ($setup.deleteSocial(index))
                      }, $setup.form.socialLinks.length > 1 ? {} : { class: 'is-visibility-hidden' }), [
                        _createVNode(_component_AppIcon, { name: "i-thrash" })
                      ], 16, _hoisted_36)
                    ]),
                    _createVNode(_component_vee_error, {
                      class: "g-cell g-cols g-cols--12",
                      name: `socialLinks:${index}:nonFieldErrors`,
                      as: "div"
                    }, {
                      default: _withCtx(({ message }) => [
                        _createElementVNode("div", _hoisted_37, _toDisplayString(message), 1)
                      ]),
                      _: 2
                    }, 1032, ["name"])
                  ]))
                }), 128)),
                _createElementVNode("p", _hoisted_38, [
                  _createElementVNode("button", {
                    class: "ds-link ds-link--styling_primary",
                    type: "button",
                    onClick: $setup.addSocialNetwork
                  }, _toDisplayString(_ctx.$t("+ Add another")), 1)
                ])
              ]),
              (!meta.valid && Object.keys(errors).length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t("Please, fix an error in form")), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createVNode(_component_VeeField, {
                      name: "agreeTerms",
                      rules: "required",
                      id: "agreeTerms",
                      type: "checkbox",
                      "checked-value": true,
                      "unchecked-value": false,
                      value: true,
                      modelValue: $setup.form.agreeTerms,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.form.agreeTerms) = $event)),
                      as: "control-checkbox"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("p", _hoisted_45, [
                      _createTextVNode(_toDisplayString(_ctx.$t('By checking this box, I consent to the processing of my personal data in accordance with the')) + " ", 1),
                      _createElementVNode("a", {
                        class: "sk-link sk-link--appearance_underline sk-link--size_xs sk-link--inline",
                        href: $setup.userAgreement,
                        target: "_blank"
                      }, _toDisplayString(_ctx.$t('User agreement')), 9, _hoisted_46),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('and')) + " ", 1),
                      _createElementVNode("a", {
                        class: "sk-link sk-link--appearance_underline sk-link--size_xs sk-link--inline",
                        href: $setup.privacyPolicy,
                        target: "_blank"
                      }, _toDisplayString(_ctx.$t('Privacy Policy')), 9, _hoisted_47)
                    ])
                  ]),
                  _createVNode(_component_VeeError, { name: "agreeTerms" }, {
                    default: _withCtx(({ message }) => [
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, _toDisplayString(message), 1)
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_50, [
                _createVNode(_component_ControlButton, {
                  styling: 'primary',
                  disabled: isSubmitting,
                  size: ['md'],
                  view: 'full',
                  type: "submit",
                  onClick: _withModifiers(($event: any) => ($setup.validateForm(validate, $setup.getCaptcha, setFieldError)), ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Send')), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"])
              ])
            ])
          ], 2)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["loading"]))
}