import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_silent_box = _resolveComponent("silent-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_silent_box, {
      ref: "silentboxRef",
      gallery: $props.items,
      visible: $setup.visible
    }, null, 8, ["gallery", "visible"]),
    _renderSlot(_ctx.$slots, "default", { openLightbox: $setup.openImg })
  ], 64))
}