import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "catalog-content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppRenderer = _resolveComponent("AppRenderer")!
  const _component_InfiniteTemplateResultsCollector = _resolveComponent("InfiniteTemplateResultsCollector")!
  const _component_UiPagination = _resolveComponent("UiPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InfiniteTemplateResultsCollector, {
        pagination: $props.pagination.value,
        items: $props.items,
        "is-new-page": !$setup.isInfinityTemplate
      }, {
        ssr: _withCtx(({ isSSR }) => [
          isSSR
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(({ items }) => [
          items
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(items, (item) => {
                return (_openBlock(), _createBlock(_component_AppRenderer, {
                  key: item.key,
                  result: item.value
                }, null, 8, ["result"]))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["pagination", "items", "is-new-page"])
    ]),
    _createVNode(_component_UiPagination, _mergeProps({
      class: "pagination-wrap pagination-wrap--variant_public pagination-wrap--align_right",
      "start-page": $setup.isInfinityTemplate ? $setup.startPage : false,
      "scroll-paginate": $props.scrollPaginate?.value,
      onInput: $setup.handlePaginate,
      onLoadMore: $setup.handleLoadMore,
      url: $props.urlWithoutPage
    }, $props.pagination), null, 16, ["start-page", "scroll-paginate", "url"])
  ], 64))
}