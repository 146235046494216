import { computed, ref, Ref, unref, watch } from 'vue'

import { omit } from 'ramda'
import debounce from 'lodash.debounce'
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy'
import { useLoadable } from '@/packages/vue-loading-state'
import { BaseResource } from '@/plugins/resource'
import type { Pagination } from '@/const'

export interface Parameters {
  limit?: number
  offset?: number
  total?: number
  search?: string
  [key: string]: any
}
export type MaybeRef<T = any> = T | Ref<T>
export type MaybeRefOrGetter<T = any> = MaybeRef<T> | (() => T)

export const DEFAULT_PAGINATION = 10

export function useSelectsDynamicOptions<
  R extends { isDisabled?: boolean; $isDisabled?: boolean },
  F = any
>(
  resource: BaseResource,
  filters?: MaybeRefOrGetter<F> | null,
  config: {
    requireDisable?: boolean
    debounceTime?: number
  } = {
    requireDisable: false,
    debounceTime: 300,
  }
) {
  const params = ref<Parameters>({
    ...unref(filters),
    limit: DEFAULT_PAGINATION,
  })
  const { load, loading, result } = useLoadable<any[]>(getResult)

  if (filters) {
    watch(
      () => unref(filters),
      (newFilters) => {
        params.value = {
          ...newFilters,
          limit: DEFAULT_PAGINATION,
        }
        load([omit(['limit'], params.value)])
      }
    )
  }

  function getResult(args = []) {
    const cancelController = new AbortController()
    const cancellable = resource.config('signal', cancelController.signal)
    const request = cancellablePromiseProxy(
      new Promise((resolve, reject) => {
        cancellable.execute(...args).then(resolve, reject)
      }),
      { cancelController }
    )
      .then(({ items, pagination }: { items: R[]; pagination: Pagination }) => {
        let list: R[] = result?.value || []
        const itemsWithDisabling: R[] = config.requireDisable
          ? items.map((el: R) => {
              if (el?.isDisabled) {
                el['$isDisabled'] = el.isDisabled
              }
              return el
            })
          : items
        if (pagination?.offset) {
          list.push(...(itemsWithDisabling as any))
        } else {
          list = itemsWithDisabling as any
        }
        params.value = { ...params.value, ...omit(['limit'], pagination) }
        return list
      })
      .catch((err) => {
        return err.name == 'AbortError' ? [] : err
      })

    return request
  }

  function search(search = undefined) {
    params.value.search = search || undefined
    params.value.offset = 0
    load([params.value])
  }

  const debounceSearch = debounce(search, config.debounceTime)

  function get() {
    if (
      undefined === params.value?.total ||
      params.value.total > params.value.offset + params.value.limit
    ) {
      if (params.value?.total && params.value.offset !== undefined) {
        params.value.offset = params.value.offset + params.value.limit
      }
      load([params.value])
    }
  }

  const list = computed(() => {
    return result.value || []
  })

  return {
    params,
    loading,
    list,
    load: load.bind(this, [params.value]),
    search,
    debounceSearch,
    get,
  }
}

export default {}
