export const tableWrapper = {
  beforeMount: (node: HTMLElement): void => {
    const tableElements = node.querySelectorAll('table')
    const length = tableElements.length
    for (let i = 0; i < length; i++) {
      const currentTable = tableElements[i]
      const wrapper = document.createElement('div')
      wrapper.classList.add('table-wrapper')

      if (currentTable.parentNode) {
        currentTable.parentNode.insertBefore(wrapper, currentTable)
      }
      wrapper.appendChild(currentTable)
    }
  },
}
