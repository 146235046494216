import { App } from 'vue'

import Control from '@/components/controls'
import Icons from '@/components/i'
import Forms from '@/components/forms'
import Tabs from '@/components/tabs'
import General from '@/components/general'
import Common from '@/components/common'
import UiComponents from '@/components/ui'
import offersCatalog from '@/components/offersCatalog'

import RsTab from './ResponsiveTabs/Tab'
import RsTabs from './ResponsiveTabs/Tabs'

const install = (app: App<Element>): void => {
  app
    .use(Icons)
    .use(Forms)
    .use(UiComponents)
    .use(Control)
    .use(General)
    .use(Common)
    .use(Tabs)
    .use(offersCatalog)
    .component('RsTabs', RsTabs)
    .component('RsTab', RsTab)
}

export default {
  install,
}
