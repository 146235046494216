import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { accessPermission } from '@/modules/user/permissions'
import { SAVED_SEARCH } from '@/modules/user/accessTokens'

const SAVED_SEARCH_PREFIX = 'cabinet:saved-searches'
const p = (name: string) => SAVED_SEARCH_PREFIX + ':' + name

export const SAVED_SEARCHES_LIST = p('list')

const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, SAVED_SEARCHES_LIST, {
      meta: {
        title: () => $t('My saved searches'),
        ...accessPermission([SAVED_SEARCH.SAVED_SEARCH.VIEW], options)
      },
    }),

    { path: '', redirect: { name: SAVED_SEARCHES_LIST } },
    
  ]
}
