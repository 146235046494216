import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-widget__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-widget", { 'is-active': !$props.collapsable || $setup.state }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($props.collapsable ? 'button': 'p'), _mergeProps({ class: "filter-widget__title" }, $props.collapsable ? { type: 'button', class: 'ds-link ds-link--styling_inherit', onClick: $setup.toggleState } : {}), {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString($props.title), 1),
          ($props.unit && $props.unit?.title)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(", " + _toDisplayString($props.unit?.title), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        ($props.collapsable)
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 0,
              "aria-hidden": "true",
              size: "xl",
              name: $setup.state ? 'i-minus' : 'i-plus'
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16)),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", { handleUpdateState: $setup.onUpdateState })
    ])
  ], 2))
}