import {
  computed,
  defineComponent,
  ref,
  type Ref,
  type SetupContext,
} from 'vue'
import { usePointerSwipe } from '@vueuse/core'
import type { UseSwipeDirection } from '@vueuse/core'
import { getVideoId } from '@/utils/helpers'

export default defineComponent({
  props: {
    sources: Array,
  },
  setup(props, { slots }: SetupContext) {
    const thumbsRef = ref(null)
    const activeIndex: Ref<number> = ref(0)
    const activeSource = computed<{
      webp?: string
      optimized?: string
      src?: string
      url?: string
    }>(() => props.sources[activeIndex.value])
    function setActiveIndex(index: number) {
      activeIndex.value = index
      if (activeSource.value?.url) {
        const { previewUrl } = getVideoId(activeSource.value.url)
        sourceRef.value.srcset = previewUrl
        imgRef.value.src = previewUrl

      } else {
        if (activeSource.value?.webp) {
          sourceRef.value.srcset = activeSource.value.webp
        }
        if (activeSource.value?.optimized || activeSource.value?.src) {
          imgRef.value.src = activeSource.value.optimized || activeSource.value.src
        } 
      }

    }
    const sourceRef = ref()
    const imgRef = ref()
    const containerWidth = computed(() => thumbsRef.value?.offsetWidth)

    // const { posStart, posEnd, direction, distanceX } = usePointerSwipe(thumbsRef, {
    //   disableTextSelect: true,
    //   onSwipe(e: PointerEvent) {
    //     if (containerWidth.value) {
    //       if (distanceX.value < 0) {
    //         const distance = Math.abs(distanceX.value)
    //         thumbsRef.value.scrollTo({ left: `${distance}px`, behavior: 'smooth' })
    //       }
    //       else {
    //         thumbsRef.value.scrollTo({ left: '0', behavior: 'smooth' })
    //       }
    //     }
    //   },
    //   onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
    //     const distance = Math.abs(distanceX.value)
    //     thumbsRef.value.scrollTo({ left: `${distance}px`, behavior: 'smooth' })
    //   },
    // })

    return () =>
      slots.default?.({
        activeIndex: activeIndex.value,
        setActiveIndex,
        thumbsRef,
        sourceRef,
        imgRef,
        activeSource: activeSource.value,
      })
  },
})
