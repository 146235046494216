import { App, defineAsyncComponent } from 'vue'

const AppTab = defineAsyncComponent(() => import('./AppTab.vue'))
const AppTabs = defineAsyncComponent(() => import('./AppTabs.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('AppTab', AppTab)
      .component('AppTabs', AppTabs)
    }
}
