import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "blog-catalog-tags" }
const _hoisted_2 = { class: "blog-catalog-tags__tag-wrap" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = {
  class: "blog-catalog-tags__tag",
  for: 'all'
}
const _hoisted_5 = ["name", "id", "value", "checked", "onChange"]
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          class: "blog-catalog-tags__checkbox",
          name: "all",
          type: "checkbox",
          id: 'all',
          value: 'all',
          onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleChangeAll($event))),
          checked: !$props.value
        }, null, 40, _hoisted_3),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("All tags")), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "blog-catalog-tags__tag-wrap",
          key: item.value
        }, [
          _createElementVNode("input", {
            class: "blog-catalog-tags__checkbox",
            name: $props.name,
            type: "checkbox",
            id: item.value,
            value: item.value,
            checked: $setup.internal == item.value,
            onChange: ($event: any) => ($setup.handleChange($event, item))
          }, null, 40, _hoisted_5),
          _createElementVNode("label", {
            class: "blog-catalog-tags__tag",
            for: item.value
          }, _toDisplayString(item.label), 9, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}