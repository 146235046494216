const ARIA_SELECTED_ATTR = 'aria-selected'
const OPEN_ATTR = 'open'
const BREAKPOINT = 1200

const xsTabs = (root: Element) => {
  const tabs: Element[] = [...root.getElementsByClassName('js-tab-xs')]
  tabs.forEach((tab: Element) => {
    const tabButtons: Element[] = [...tab.getElementsByClassName('rs-accordion')]
    tabButtons.forEach((button) => {
      button.addEventListener('click', () => {
        tabs.forEach((btn) => {
          btn.removeAttribute(ARIA_SELECTED_ATTR)
          btn.removeAttribute(OPEN_ATTR)
        })
        tab.setAttribute(ARIA_SELECTED_ATTR, 'true')
      })
    })
  })
  if (tabs.length > 0) {
    const [t] = tabs
    const tabButtons: Element[] = [...t.getElementsByClassName('rs-accordion')]
    tabButtons[0].dispatchEvent(new Event('click'))
    t.setAttribute(OPEN_ATTR, 'true')
  }
}

const xlTabs = (root: Element) => {
  const tabsXl = [...root.getElementsByClassName('js-tab-xl')];
  const [tabContentParent] = [...root.getElementsByClassName('js-rs-tabpanel')];
  if (tabContentParent) {
    const tabContent = [...tabContentParent.children];
    tabsXl.forEach(tab => {
      tab.addEventListener('click', () => {
        tabsXl.forEach(btn => btn.setAttribute('aria-selected', 'false'));
        tab.setAttribute('aria-selected', 'true');
        tabContent.forEach(content => (content as HTMLElement).style.display = 'none');
        const current = tabContent.find(content => (content as HTMLElement).dataset.tab === (tab as HTMLElement).dataset.tab)
        if (current) {
          (current as HTMLElement).style.display = 'block';
        }
      });
    });
    if (tabsXl.length > 0) {
      tabsXl[0].dispatchEvent(new Event('click'))
    }
  }
}

const pureTabs = () => {
  const ww = window.innerWidth
  const action = ww < BREAKPOINT ? xsTabs : xlTabs
  const tabContentParents = [...document.querySelectorAll('.js-rs-tabs')];
  tabContentParents.forEach(el => { action(el) })
}

if ('development' === process.env.NODE_ENV) {
  window.addEventListener('load', () => {
    pureTabs()
  })
} else {
  document.addEventListener('DOMContentLoaded', () => {
    pureTabs()
  })
}

function _calculateScrollbarWidth() {
  document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
}
// recalculate on resize
window.addEventListener('resize', _calculateScrollbarWidth, false);
// recalculate on dom load
document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false); 
// recalculate on load (assets loaded as well)
window.addEventListener('load', _calculateScrollbarWidth);