import { App, defineAsyncComponent } from 'vue';
// import VueGoogleMaps from '@fawmi/vue-google-maps';
import { vIntersectionObserver, vOnClickOutside } from '@vueuse/components';
import {
  // Directives
  vTooltip,
  // Components
  Dropdown,
} from 'floating-vue'
import VueDatePicker from 'vue-datepicker-next';
import VueSlider from 'vue-slider-component'
import VueRouterPermission from '@/packages/vue-router-permissions'

import VueSilentbox from '@/packages/silent-box/main'
// import 'vue-silentbox/dist/style.css'

import 'vue-datepicker-next/index.css';
// require(`vue2-datepicker/locale/${window.language}`);

export default {
  install(app: App<Element>): void {
    app
      .directive('on-click-outside', vOnClickOutside)
      .directive('intersection-observer', vIntersectionObserver)
    app
      // .use(VueGoogleMaps, {
      //   load: {
      //     key: window.CONFIG.GOOGLE_MAPS_KEY || '',
      //     language: window.language,
      //   },
      // })
      .component('VDropdown', Dropdown)
      .component('DatePicker', VueDatePicker)
      .component('VueSlider', VueSlider)
      .use(VueSilentbox)
      .use(VueRouterPermission)
      .directive('tooltip', vTooltip)
      
  },
}
