import {
  SetupContext,
  Transition,
  nextTick,
  ref,
} from 'vue'
interface Props {
  breakPoint: number,
  closedDefault: boolean,
  selectedName: string,
}
export default function responsiveTabs(
  props: Props,
  { slots, emit }: SetupContext
) {
    // let selectedIndex: number | null = 0
    // let selectedSlotName: string | null = props.selectedName

    // function switchTab(
    //   e: Event,
    //   slotName: string,
    //   index: number,
    //   isDisabled: boolean
    // ) {
    //   if (!isDisabled) {
    //     if (
    //       props.breakPoint &&
    //       window.innerWidth <= Number(props.breakPoint) &&
    //       selectedIndex === index
    //     ) {
    //       selectedIndex = null
    //       selectedSlotName = null
    //       return
    //     }
    //     selectedIndex = index
    //     selectedSlotName = slotName
    //     emit('on-select', e, index)
    //   }
    // }
    // const contentList = Object.keys(slots)
    //   .filter((slotName) => slotName.includes('content-'))
    //   .reduce((arr, key) => {
    //     arr.push(slots[key])
    //     return arr
    //   }, [])

    // const tabControlsListRef = ref()  

    // function setFocus(category) {
    //   nextTick(() => {
    //     const node = tabControlsListRef.value.querySelector(`[aria-selected="true"]`)
    //     if (node) {
    //       node?.focus?.()
    //     }
    //   })
    // }

  

    // function onKeypress(event: KeyboardEvent, slotName: string, index: number, isDisabled: boolean) {
    //   let newIndex = index
    //   let component
    //   let slotPrevName
    //   let disabledNext
    //   let slotNextName
    //   let disabledPrev
    //   let isDisabledNext
    //   let isDisabledPrev
    //   switch (event.key) {
    //     case '32': // space
    //     case 'Enter': // return
    //       switchTab(event, slotName, index, isDisabled)
    //       break

    //     case 'ArrowLeft': // left
    //       newIndex = index - 1 >= 0 ? index - 1 : contentList.length - 1;
    //       [component] = contentList[newIndex]();
    //       slotPrevName =component.props.slot
    //       disabledNext = component.props.disabled
    //       isDisabledNext = true === disabledNext || '' === disabledNext
    //       switchTab(event, slotPrevName, newIndex, isDisabledNext)
    //       setFocus(contentList[newIndex])
    //       break

    //     case 'ArrowRight': // right
    //       newIndex = index + 1 > contentList.length - 1 ? 0 : index + 1;
    //       [component] = contentList[newIndex]()
    //       slotNextName = component.props.slot
    //       disabledPrev = component.props.disabled
    //       isDisabledPrev = true === disabledPrev || '' === disabledPrev
    //       switchTab(event, slotNextName, newIndex, isDisabledPrev)
    //       setFocus(contentList[newIndex])
    //       break

    //     default:
    //       break
    //   }
    //   if ('Tab' !== event.key) {
    //     event.stopPropagation()
    //     event.preventDefault()
    //   }
    // }

    // function render() {
    //   if (props.breakPoint && window.innerWidth < props.breakPoint) {
    //     // accordion render
    //     return () => (
    //       <div class="rs-tabs">
    //         {slots['prepend'] && slots['prepend']()}
    //         <div class="rs-tabs__accordion-list">
    //           {slots['prepend-list'] && slots['prepend-list']()}
    //           {contentList.map((child, index) => {
    //             const [component] = child()
    //             const {
    //               title,
    //               slot: slotName,
    //               'title-slot': titleSlot,
    //               disabled,
    //             } = component.props
    //             const isDisabled = true === disabled || '' === disabled

    //             return (
    //               <details
    //                 class="rs-tabs__tab rs-tabs__tab--accordion"
    //                 key={
    //                   selectedSlotName === slotName
    //                     ? Date.now()
    //                     : slotName
    //                 }
    //                 // remove area! details is not supporter aria-* attributes
    //                 // details support global attributes
    //                 // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#attributes
    //                 // it's mean details support aria-*
    //                 aria-selected={
    //                   selectedSlotName === slotName
    //                 }
    //                 aria-disabled={isDisabled}
    //                 {...(selectedSlotName === slotName
    //                   ? { open: true }
    //                   : { open: false })}
    //               >
    //                 <summary
    //                   class="rs-accordion"
    //                   onClick={(e) => switchTab(e, slotName, index, isDisabled)}
    //                 >
    //                   {slots[titleSlot]
    //                     ? slots[titleSlot]()
    //                     : title || index + 1}
    //                 </summary>
    //                 <div class="rs-accordion-content">
    //                   {slots[slotName]?.()}
    //                 </div>
    //               </details>
    //             )
    //           })}
    //           {slots['append-list'] && slots['append-list']()}
    //         </div>
    //         {slots['append'] && slots['append']()}
    //       </div>
    //     )
    //   } else {
    //     //  tabs render

    //     return () => (
    //       <div class="rs-tabs">
    //         {slots['prepend'] && slots['prepend']()}
    //         <div class="rs-tabs__tablist" role="tablist" ref={tabControlsListRef}>
    //           {slots['prepend-list'] && slots['prepend-list']()}
    //           {contentList.map((child, index) => {
    //             const [component] = child()
    //             const {
    //               title,
    //               slot: slotName,
    //               'title-slot': titleSlot,
    //               disabled,
    //             } = component.props
    //             const isDisabled = true === disabled || '' === disabled

    //             return (
    //               <button
    //                 class="rs-tabs__tab rs-tabs__tab--tab"
    //                 role="tab"
    //                 type="button"
    //                 aria-selected={selectedSlotName === slotName}
    //                 tabindex={selectedSlotName === slotName ? 0 : -1}
    //                 aria-disabled={isDisabled}
    //                 onClick={(e) => switchTab(e, slotName, index, isDisabled)}
    //                 onKeydown={(e) => onKeypress(e, slotName, index, isDisabled)}
    //               >
    //                 {slots[titleSlot] ? slots[titleSlot]() : title || index}
    //               </button>
    //             )
    //           })}
    //           {slots['append-list'] && slots['append-list']()}
    //         </div>
    //         <div>
    //           {selectedSlotName && slots[selectedSlotName as string]?.()}
    //         </div>
    //         {slots['append'] && slots['append']()}
    //       </div>
    //     )
    //   }
    // }
    // return render()
    return <div></div>
  
  }
