import { curry } from 'ramda'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import { baseResource } from '@/plugins/resource'
import { camelize } from '@/utils/strings'

import {
  // defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons'

const filtersGetterBase = makeTemplateGetter('{/filters*/}')
const filtersGetter = (parameters: string) =>
  filtersGetterBase(parameters).slice(1) || null

const partGetter = (name: string, prefix: string) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`)
  return (parameters: Record<string, unknown>) => {
    const result = base(parameters)
    return result === prefix ? null : result
  }
}
const brand_model_getter = () => {
  const base = makeTemplateGetter(`{/brands_models*}`)
  return (parameters: Record<string, any>) => {
    let brands_models = []
    if (parameters.brands_models && parameters.brands_models.length) {
      brands_models = parameters.brands_models.map((item) => {
        if (item.model) {
          return `${item.brand}_${item.model}`
        } else {
          return item.brand
        }
      })
    }
    const result = base({ brands_models })
    return result
  }
}

const pageGetter = partGetter('page', 'page')
const labelGetter = makeTemplateGetter('{/label}')
const ownerGetter = makeTemplateGetter('{/owner}')
const tokenStringGetter = makeTemplateGetter('{/token_string}')
const categoryGetter = makeTemplateGetter('{/category}')
const categoriesGetter = makeTemplateGetter('{/categories*}')
const brandsGetter = makeTemplateGetter('{/brands*}')
const brandModelGetter = makeTemplateGetter('{/brand_models*}')
const brandsModelGetter = brand_model_getter()
const locationCountriesGetter = makeTemplateGetter('{/locationCountries*}')
const getParametersGetter = makeTemplateGetter(
  '{?limit,offset,sort,search_string,search_id}'
)
const sortGetter = makeTemplateGetter('{?sort,search,search_id}')

const order_map = {
  label: labelGetter,
  owner: ownerGetter,
  category: categoryGetter,
  categories: categoriesGetter,
  brands: brandsGetter,
  // 'price-min': priceMinGetter,
  // 'price-max': priceMaxGetter,
  // 'first-registration-min': firstRegistrationMinGetter,
  // 'first-registration-max': firstRegistrationMaxGetter,
  brand_models: brandModelGetter,
  brands_models: brandsModelGetter,
  locationCountries: locationCountriesGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  sort: sortGetter,
  page: pageGetter,
  token_string: tokenStringGetter,
} as const

const initOrder = {
  path: ['prefix', 'brands', 'filters'],
}

export const urlGetter = curry(
  (
    [prefix, postfix]: [string, string],
    {
      path,
      query = [],
    }: { path: (keyof typeof order_map)[]; query?: (keyof typeof order_map)[] },
    p: unknown
  ) => {
    const base = { prefix, postfix }
    const path_parts = path.reduce(
      (acc, x, i) => {
        acc[i] = order_map[x] ? order_map[x](p) : base[x as keyof typeof base]
        return acc
      },
      Array.from({ length: path.length }, () => null)
    )
    const query_params = query.reduce((acc, x) => {
      acc.push(order_map[x](p))
      return acc
    }, [] as string[])
    return (
      `${path_parts.filter((x) => x !== null).join('/')}/`
        .replace(/%2F/g, '/')
        .replace(/\/+/gim, '/')
        .replace(/\/\//gim, '/')
        .replace(/\/\//gim, '/')
        .replace(/%2C/g, ',') + query_params.join('')
    )
  }
)

export const friendlyUrlGenerator = (
  url: string[],
  resource: typeof baseResource,
  order = initOrder
) => {
  return resource.url(urlGetter(url, order)).transform(jsonTransformer)
}
// .transform(defaultDataTransformer)

export const filtersTransformFrom = (value, filtersList) => {
  const result = filtersList.reduce((acc, el) => {
    if (el?.slug) {
      if (value[el.slug]) {
        if (Array.isArray(value[el.slug])) {
          acc[el.slug] = value[el.slug].reduce(
            (nestedAcc, val: number | [number, number[]]) => {
              if (Array.isArray(val)) {
                const [id, nested] = val

                const res = el.choices.find((option) => option.id === id)
                if (res) {
                  let nestedResult = []
                  if (res?.models) {
                    nestedResult = (nested.length ? nested : [null]).map(
                      (id) => ({
                        brand: res,
                        model: res.models.find((el) => id === el.id) || null,
                      })
                    )
                  } else {
                    nestedResult = [res]
                  }
                  if (nestedResult) nestedAcc.push(...nestedResult)
                }
              }
              const res = el.choices.find((option) => option.id == val)
              if (res) nestedAcc.push(res)
              return nestedAcc
            },
            []
          )
          return acc
        } else {
          // if(value[el.slug] instanceof Object && (Object.hasOwn(value[el.slug], 'min') || Object.hasOwn(value[el.slug], 'max'))) {
          //   acc[el.slug] =

          // } else {
          acc[el.slug] = value[el.slug]
          return acc
        }
      } else {
        if (acc[el.slug]) {
          return acc
        }
        // const [root, ids] =
        //   value.attributes.find(([id, ids]) => id === el.id) || []
        // if (ids) {
        //   if (el.choices) {
        //     acc[el.slug] = ids.map((id) =>
        //       el.choices.find((val) => id === val.id)
        //     )
        //   }
        //   return acc
        // } else {
          acc[el.slug] = undefined
          return acc
        // }
      }
    }

    return acc
  }, {})
  return result
}
