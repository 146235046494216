import { defineAsyncComponent, markRaw, defineComponent, ref } from "vue";
import { useModalOpener } from "@/composables/useModalOpener";
import { useComparisonsStore } from "./store";

const ComparisonsCategories = defineAsyncComponent(() => import('./ComparisonCategoriesList.vue'))


export default defineComponent({
  props: {
    comparisonUrl: String,
  },
  setup(props, { slots }) {

    const comparisonsStore = useComparisonsStore()

    comparisonsStore.getCount()

    function openModal() {
      const { open } = useModalOpener({
        component: markRaw(ComparisonsCategories), 
        comparisonUrl: props.comparisonUrl,
      })
      open()
    }

    return () => slots.default?.({
      count: comparisonsStore.count,
      openModal,
    })
  }
})
