import { r, createSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'commercial-offers'

export const commercialOffersApi = {
  createPreview: r('/create-preview/', MODEL, createSender),
  create: r('/create/', MODEL, createSender),
  retrieve: r('/detail{/id}/', MODEL),
  update: r('/detail{/id}/', MODEL, createSender).config('method', 'PATCH'),
  list: r(q('/list/', ['filters']), MODEL),
  filtersList: r('/filter/list/', MODEL),
  getAnOffer: r('/get-an-offer/', MODEL, createSender),
  buyNow: r('/buy-now/', MODEL, createSender),
  getPriceMonthly: r('/get-price-monthly/', MODEL, createSender),
}
