import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMainPageCategoryStore = defineStore('mainPageCategoryStore', () => {

  const category = ref<{
    title: string
    slug: string
    id: number
  }>(null)


  return {
    category,
  }
})
