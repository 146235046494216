import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "listRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {
      activeCategory: _ctx.activeCategory,
      radioPrefixName: _ctx.radioPrefixName,
      onChangeCategory: _ctx.onChangeCategory,
      onKeypress: _ctx.onKeypress
    })
  ], 512))
}