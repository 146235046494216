import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import CatalogView from './CatalogView.vue'
import CatalogContent from './CatalogContent.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('CatalogView', CatalogView)
      .component('CatalogContent', CatalogContent)
  }
}

