// export function friendlyUrlParser() {
//   let path = window.location.pathname
//   const zero = 0
//   const parsedUrl: Record<string, string[]> = {}
//   if (path.indexOf('filters') > zero) {
//     path = path.split('/page')[0]
//     const hashes = path.split('filters/')[1].split(';')
//     hashes.map((hash) => {
//       const [key] = hash.split('=')
//       let val: string | string[]
//       ;[, val] = hash.split('=')

//       parsedUrl[key] = []
//       if (val.indexOf(',') > zero) {
//         val = val.split(',')
//         val.forEach((v) => {
//           if (v.indexOf('/') > zero) {
//             parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
//           } else {
//             parsedUrl[key].push(decodeURIComponent(v))
//           }
//         })
//       } else if (val.indexOf('/') > zero) {
//         parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
//       } else {
//         parsedUrl[key].push(decodeURIComponent(val))
//       }
//     })
//   }
//   return parsedUrl
// }
export function friendlyUrlParser(splittedPart: string) {
  let path = decodeURIComponent(new URL(window.location.href).toString());
  const zero = 0;
  let page = 1
  // let p = ''
  const parsedUrl: Record<string, string[]> = {};
  const [pathWithoutPage, p = ''] = path.split('/page');
  if (p) {
    page = Number(p.split('/')?.[1]) || page;
  }
  if (pathWithoutPage && pathWithoutPage.includes(splittedPart)) {
    [, path] = pathWithoutPage.split(splittedPart);
    if (!path) {
      return { parsedUrl, page };
    }
    const hashes = path.split('/')[0].split(';').filter(hash => !!hash);
    hashes.map(hash => {
      let val: string | string[]
      const [key] = hash.split('=');
      [, val] = hash.split('=');

      parsedUrl[key as string] = [];
      if (val && val.includes(',')) {
        val = val.split(',');
        val.forEach(v => {
          if (v.includes('/')) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]));
          } else {
            parsedUrl[key].push(decodeURIComponent(v));
          }
        });
      } else if (val && val.includes('/')) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]));
      } else {
        parsedUrl[key].push(decodeURIComponent(val));
      }
      return { parsedUrl, page };
    });
  }
  return { parsedUrl, page };
}

