import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import { commercialOffersApi } from '@/services/commercialOffers.service'

const PROFILE_PREFIX = 'cabinet:commercial-offer'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const COMMERCIAL_OFFERS_CREATE = p('create')
export const COMMERCIAL_OFFERS_EDIT = p('edit')
export const COMMERCIAL_OFFERS_LIST = p('list')

const Create = () => import('./views/Create.vue')
const Edit = () => import('./views/Edit.vue')
const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, COMMERCIAL_OFFERS_LIST, {
      meta: {
        title: () => $t('My commercial offers'),
      },
    }),
    route('create/', Create, COMMERCIAL_OFFERS_CREATE, {
      meta: {
        title: () => $t('Create commercial offer'),
        fallbackRouteName: COMMERCIAL_OFFERS_LIST,
      },
    }),

    receiveRoute(commercialOffersApi.retrieve, [
      route('edit/', Edit, COMMERCIAL_OFFERS_EDIT, {
        // meta: accessPermission(APPLICATIONS_VIEW, options),
        meta: {
          title: ({ id }) => $t('Edit commercial offer') + ' #' + id,
          fallbackRouteName: COMMERCIAL_OFFERS_LIST,
        },
      }),
      { path: '', redirect: { name: COMMERCIAL_OFFERS_EDIT } },
    ]),
    { path: '', redirect: { name: COMMERCIAL_OFFERS_LIST } },
  ]
}
