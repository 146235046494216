import { defineComponent } from 'vue'

export default defineComponent({
  setup(_, { slots }) {

    function updateUrl(hash: string) {
      history.pushState({}, "", hash)
    }

    return () => slots.default?.({
      updateUrl,
    })
  }
})
