import { defineAsyncComponent, App } from 'vue'
import { upperFirst, camelCase } from '@utils/text'

const AlertCircle =  defineAsyncComponent(() => import("./AlertCircle.vue"))
const ArchiveRestore =  defineAsyncComponent(() => import("./ArchiveRestore.vue"))
const ArrowDown =  defineAsyncComponent(() => import("./ArrowDown.vue"))
const ArrowDownToLine =  defineAsyncComponent(() => import("./ArrowDownToLine.vue"))
const ArrowLeft =  defineAsyncComponent(() => import("./ArrowLeft.vue"))
const ArrowRight =  defineAsyncComponent(() => import("./ArrowRight.vue"))
const ArrowTopRight =  defineAsyncComponent(() => import("./ArrowTopRight.vue"))
const ArrowUp =  defineAsyncComponent(() => import("./ArrowUp.vue"))
const Calendar =  defineAsyncComponent(() => import("./Calendar.vue"))
const Category =  defineAsyncComponent(() => import("./Category.vue"))
const CategoryWide =  defineAsyncComponent(() => import("./CategoryWide.vue"))
const Chart =  defineAsyncComponent(() => import("./Chart.vue"))
const Check =  defineAsyncComponent(() => import("./Check.vue"))
const CheckFill =  defineAsyncComponent(() => import("./CheckFill.vue"))
const ChevronLeft =  defineAsyncComponent(() => import("./ChevronLeft.vue"))
const ChevronRight =  defineAsyncComponent(() => import("./ChevronRight.vue"))
const ChevronUp =  defineAsyncComponent(() => import("./ChevronUp.vue"))
const Close =  defineAsyncComponent(() => import("./Close.vue"))
const Dice =  defineAsyncComponent(() => import("./Dice.vue"))
const DocumentCopy =  defineAsyncComponent(() => import("./DocumentCopy.vue"))
const DocumentDownload =  defineAsyncComponent(() => import("./DocumentDownload.vue"))
const DocumentFilter =  defineAsyncComponent(() => import("./DocumentFilter.vue"))
const ElementPlus =  defineAsyncComponent(() => import("./ElementPlus.vue"))
const Eye =  defineAsyncComponent(() => import("./Eye.vue"))
const FileText =  defineAsyncComponent(() => import("./FileText.vue"))
const Files =  defineAsyncComponent(() => import("./Files.vue"))
const GlobalSearch =  defineAsyncComponent(() => import("./GlobalSearch.vue"))
const Grid =  defineAsyncComponent(() => import("./Grid.vue"))
const Heart =  defineAsyncComponent(() => import("./Heart.vue"))
const Info =  defineAsyncComponent(() => import("./Info.vue"))
const InfoCircle =  defineAsyncComponent(() => import("./InfoCircle.vue"))
const Key =  defineAsyncComponent(() => import("./Key.vue"))
const LayoutGrid =  defineAsyncComponent(() => import("./LayoutGrid.vue"))
const Link =  defineAsyncComponent(() => import("./Link.vue"))
const LockSlash =  defineAsyncComponent(() => import("./LockSlash.vue"))
const Logout =  defineAsyncComponent(() => import("./Logout.vue"))
const MessageEdit =  defineAsyncComponent(() => import("./MessageEdit.vue"))
const Minus =  defineAsyncComponent(() => import("./Minus.vue"))
const More =  defineAsyncComponent(() => import("./More.vue"))
const MoreVertical =  defineAsyncComponent(() => import("./MoreVertical.vue"))
const Move =  defineAsyncComponent(() => import("./Move.vue"))
const PasswordCheck =  defineAsyncComponent(() => import("./PasswordCheck.vue"))
const Pencil =  defineAsyncComponent(() => import("./Pencil.vue"))
const PencilThin =  defineAsyncComponent(() => import("./PencilThin.vue"))
const Phone =  defineAsyncComponent(() => import("./Phone.vue"))
const PlayCircle =  defineAsyncComponent(() => import("./PlayCircle.vue"))
const Plus =  defineAsyncComponent(() => import("./Plus.vue"))
const ReceiptEdit =  defineAsyncComponent(() => import("./ReceiptEdit.vue"))
const RotateLeft =  defineAsyncComponent(() => import("./RotateLeft.vue"))
const Save =  defineAsyncComponent(() => import("./Save.vue"))
const Scales =  defineAsyncComponent(() => import("./Scales.vue"))
const Search =  defineAsyncComponent(() => import("./Search.vue"))
const Send =  defineAsyncComponent(() => import("./Send.vue"))
const Settings =  defineAsyncComponent(() => import("./Settings.vue"))
const Share =  defineAsyncComponent(() => import("./Share.vue"))
const Sms =  defineAsyncComponent(() => import("./Sms.vue"))
const Star =  defineAsyncComponent(() => import("./Star.vue"))
const Task =  defineAsyncComponent(() => import("./Task.vue"))
const Thrash =  defineAsyncComponent(() => import("./Thrash.vue"))
const Trash =  defineAsyncComponent(() => import("./Trash.vue"))
const UserSquare =  defineAsyncComponent(() => import("./UserSquare.vue"))
const WalletMoney =  defineAsyncComponent(() => import("./WalletMoney.vue"))
const Stop =  defineAsyncComponent(() => import("./Stop.vue"))
const Refresh =  defineAsyncComponent(() => import("./Refresh.vue"))


const install = (app: App<Element>, prefix='I'): void => {

  app.component(`${prefix}AlertCircle`, AlertCircle)
  app.component(`${prefix}ArchiveRestore`, ArchiveRestore)
  app.component(`${prefix}ArrowDown`, ArrowDown)
  app.component(`${prefix}ArrowDownToLine`, ArrowDownToLine)
  app.component(`${prefix}ArrowLeft`, ArrowLeft)
  app.component(`${prefix}ArrowRight`, ArrowRight)
  app.component(`${prefix}ArrowTopRight`, ArrowTopRight)
  app.component(`${prefix}ArrowUp`, ArrowUp)
  app.component(`${prefix}Calendar`, Calendar)
  app.component(`${prefix}Category`, Category)
  app.component(`${prefix}CategoryWide`, CategoryWide)
  app.component(`${prefix}Chart`, Chart)
  app.component(`${prefix}Check`, Check)
  app.component(`${prefix}CheckFill`, CheckFill)
  app.component(`${prefix}ChevronLeft`, ChevronLeft)
  app.component(`${prefix}ChevronRight`, ChevronRight)
  app.component(`${prefix}ChevronUp`, ChevronUp)
  app.component(`${prefix}Close`, Close)
  app.component(`${prefix}Dice`, Dice)
  app.component(`${prefix}DocumentCopy`, DocumentCopy)
  app.component(`${prefix}DocumentDownload`, DocumentDownload)
  app.component(`${prefix}DocumentFilter`, DocumentFilter)
  app.component(`${prefix}ElementPlus`, ElementPlus)
  app.component(`${prefix}Eye`, Eye)
  app.component(`${prefix}FileText`, FileText)
  app.component(`${prefix}Files`, Files)
  app.component(`${prefix}GlobalSearch`, GlobalSearch)
  app.component(`${prefix}Grid`, Grid)
  app.component(`${prefix}Heart`, Heart)
  app.component(`${prefix}Info`, Info)
  app.component(`${prefix}InfoCircle`, InfoCircle)
  app.component(`${prefix}Key`, Key)
  app.component(`${prefix}LayoutGrid`, LayoutGrid)
  app.component(`${prefix}Link`, Link)
  app.component(`${prefix}LockSlash`, LockSlash)
  app.component(`${prefix}Logout`, Logout)
  app.component(`${prefix}MessageEdit`, MessageEdit)
  app.component(`${prefix}Minus`, Minus)
  app.component(`${prefix}More`, More)
  app.component(`${prefix}MoreVertical`, MoreVertical)
  app.component(`${prefix}Move`, Move)
  app.component(`${prefix}PasswordCheck`, PasswordCheck)
  app.component(`${prefix}Pencil`, Pencil)
  app.component(`${prefix}PencilThin`, PencilThin)
  app.component(`${prefix}Phone`, Phone)
  app.component(`${prefix}PlayCircle`, PlayCircle)
  app.component(`${prefix}Plus`, Plus)
  app.component(`${prefix}ReceiptEdit`, ReceiptEdit)
  app.component(`${prefix}RotateLeft`, RotateLeft)
  app.component(`${prefix}Save`, Save)
  app.component(`${prefix}Scales`, Scales)
  app.component(`${prefix}Search`, Search)
  app.component(`${prefix}Send`, Send)
  app.component(`${prefix}Settings`, Settings)
  app.component(`${prefix}Share`, Share)
  app.component(`${prefix}Sms`, Sms)
  app.component(`${prefix}Star`, Star)
  app.component(`${prefix}Task`, Task)
  app.component(`${prefix}Thrash`, Thrash)
  app.component(`${prefix}Trash`, Trash)
  app.component(`${prefix}UserSquare`, UserSquare)
  app.component(`${prefix}WalletMoney`, WalletMoney)
  app.component(`${prefix}Stop`, Stop)
  app.component(`${prefix}Refresh`, Refresh)
}

export default { install }

