type ErrorMessage = {
  message: string;
};

type ErrorEntry = {
  [key: string]: ErrorMessage;
};

type LastErrorType = {
  data?: any;
  code?: number;
  errors?: {
    domain?: string;
    state?: any;
  }[];
};

type ControllerType = {
  setFieldError?: (field: string, message: string) => void;
  setErrors?: (fields: Record<string, string>) => void;
};

const messageGetter = (x: ErrorMessage): string => x.message;

export function defaultValidatorErrorsParser(errors: ErrorEntry): { [key: string]: string } {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = messageGetter(errors[x][0])
    return acc
  }, {})
}

export function simpleValidatorErrorsParser(errors: ErrorEntry): { [key: string]: string } {
  return Object.keys(errors).reduce((acc, x) => {
    acc['nonFieldErrors'] = errors.message[0]
    return acc
  }, {})
}
type Error = { message: string; reason: string }
type Errors =
  | {
      [key: string]: Error[]
    }
  | { [key: string]: Errors | Errors[] }
  | { [key: string]: Errors | Errors[] }[]
  | { message: string; reason: string }

type TransformedErrors = Partial<Record<string, string>>
export function nestedValidatorErrorsParser(
  errors: Errors,
  initKey = '',
  accumulator: TransformedErrors = {}
): TransformedErrors {
  return Object.keys(errors).reduce((acc: TransformedErrors, x: string) => {
    const key = initKey ? `${initKey}:${x}` : x
    const currentError: Errors = (errors as Errors)[
      x as keyof Errors
    ] as unknown as Errors
    if ('object' !== typeof currentError) return acc
    if (Array.isArray(currentError)) {
      if (Object.prototype.hasOwnProperty.call(currentError[0], 'message')) {
        acc[key] = messageGetter(currentError[0] as unknown as Error)
        return acc
      }
      for (let i = 0, n = currentError.length as number; i < n; i++) {
        nestedValidatorErrorsParser(currentError[i], `${key}:${i}`, acc)
      }
      return acc
    }
    return nestedValidatorErrorsParser(currentError, key, acc)
  }, accumulator)
}


export function catchFormErrors(
  promise: Promise<any>,
  controller: ControllerType,
  parser?: (errors: ErrorEntry) => { [key: string]: string }
): Promise<any> {
  return promise.catch(e => {
    const clientError = 400
    const serverError = 500
    console.warn(e, 'EERRROORRR');

    if (!e.status || clientError > e.status || serverError <= e.status) {
      throw e
    }
    
    const errors = {}
    if (!parser) return Promise.reject(e)

    return e.json().then(body => {

      body.errors.forEach(error => {
        if ('request' === error.domain && error.state) {
          Object.assign(errors, error.state)
        }
      })
      try {
        updateValidator(errors, controller, parser)
      } catch {
        throw e
      }

      return e
    })

  })
}

export function updateValidator(
  errors: ErrorEntry,
  controller: ControllerType,
  parser: (errors: ErrorEntry) => { [key: string]: string }
): void {
  controller.setFieldError?.('nonFieldErrors', '')
  const parsed = parser(errors)
  controller.setErrors?.(parsed)
}

export function submit(
  send: Promise<any>,
  control: ControllerType,
  parser: (errors: ErrorEntry) => { [key: string]: string } = defaultValidatorErrorsParser
): Promise<any> {
  return catchFormErrors(send, control, parser)
}

export function useSubmit(
  send: Promise<any>,
  control: ControllerType,
  parser: (errors: ErrorEntry) => { [key: string]: string } = defaultValidatorErrorsParser
): Promise<any> {
  return submit(send, control, parser)
}
