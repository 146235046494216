import { computed, defineComponent, onUnmounted, onMounted, ref, watch } from 'vue'
import { useSnapCarousel } from '@/composables/useSnapSlider'
import { props } from 'ramda'

export default defineComponent({
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { slots }) {
    const sliderRef = ref(null)

    const {
      snapSlider,
      init,
      checkAvailability,
      isAvailable,
      isHasPrev,
      isHasNext,
      nextSlide,
      prevSlide,
      goToSlide,
    } = useSnapCarousel(sliderRef, 
      {
        loop: false,
        align: 'start',
        on: {
          'change.scroll': (instance) => {
            currentSlideIndex.value = instance.current
          }
        },
        ...props.options
      })

    onMounted(() => {
      init({ current: 1 })
      checkAvailability(sliderRef.value)
    })
    onUnmounted(() => {
      snapSlider.value?.destroy?.()
    })

    const currentSlideIndex = ref(1)

    watch(() => snapSlider.value?.current,(c: number) => {
      currentSlideIndex.value = c
    })

    return () =>
      slots.default?.({
        sliderRef,
        isAvailableScroll: isAvailable.value,
        isHasPrev: isHasPrev.value,
        isHasNext: isHasNext.value,
        prevSlide: prevSlide,
        nextSlide: nextSlide,
        goToSlide,
        current: currentSlideIndex.value,
      })
  },
})
