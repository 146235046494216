import { defineComponent, h } from 'vue'
import CatalogView from '../catalog/CatalogView.vue'
import VacanciesCatalogFilters from './VacanciesCatalogFilters.vue'
import { ajaxVacancyList } from '@/services/vacancies.service'

export default defineComponent({
  setup(props, { attrs, slots }) {
    return () =>
      h(
        CatalogView,
        {
          ...props,
          ...attrs,
          resource: ajaxVacancyList,
          catalogFiltersComponent: VacanciesCatalogFilters,
        },
        {
          default: () => slots.default?.(),
        }
      )
  },
})
