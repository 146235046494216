import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--align_center g-row--appearance_spaced g-row--space_lg g-row--space_xl-mac" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12 g-cols--6-md g-cols--4-xl" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--6 sk-space_mt--10-lg" }
const _hoisted_9 = { class: "global-error" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--6 sk-space_mt--10-lg" }
const _hoisted_11 = { class: "global-error" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--6 sk-space_mt--10-lg" }
const _hoisted_13 = { class: "global-error" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--6 sk-space_mt--10-lg" }
const _hoisted_15 = { class: "global-error" }
const _hoisted_16 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_17 = { class: "g-row g-row--align_center g-row--appearance_spaced" }
const _hoisted_18 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_20 = { class: "ds-caption ds-caption--size_sm ds-caption--appearance_start" }
const _hoisted_21 = ["href"]
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_24 = { class: "global-error" }
const _hoisted_25 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_26 = { class: "g-row g-row--align_center g-row--appearance_spaced" }
const _hoisted_27 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_28 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_29 = { class: "ds-caption ds-caption--size_sm ds-caption--appearance_start" }
const _hoisted_30 = ["href"]
const _hoisted_31 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_32 = { class: "global-error" }
const _hoisted_33 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_34 = { class: "global-error" }
const _hoisted_35 = { class: "g-cell g-cols g-cols--12 sk-space_mt sk-space_mt--6 sk-space_mt--10-lg" }
const _hoisted_36 = { class: "g-row g-row--align_center g-row--justify_end g-row--appearance_spaced" }
const _hoisted_37 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 g-cols--auto-md"
}
const _hoisted_38 = { class: "global-error" }
const _hoisted_39 = { class: "g-cell g-cols g-cols--12 g-cols--narrow-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DInput = _resolveComponent("DInput")!
  const _component_d_control_recaptcha = _resolveComponent("d-control-recaptcha")!
  const _component_DPhone = _resolveComponent("DPhone")!
  const _component_DSelect = _resolveComponent("DSelect")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_LoadingWrapper = _resolveComponent("LoadingWrapper")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createBlock(_component_VeeForm, {
    onSubmit: $setup.request,
    ref: "formRef"
  }, {
    default: _withCtx(({ errors, meta, validate, values, setFieldError, isSubmitting }) => [
      _createVNode(_component_LoadingWrapper, {
        loading: $setup.isLoad || isSubmitting
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_DInput, {
                name: "contactFirstName",
                id: "contactFirstName",
                size: ['md', 'md-xl'],
                rules: 'required',
                "input-label": $setup.labels.contactFirstName,
                modelValue: $setup.form.contactFirstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.contactFirstName) = $event))
              }, null, 8, ["input-label", "modelValue"]),
              _createVNode(_component_d_control_recaptcha, {
                class: "control-descriptor--clear",
                modelValue: $setup.form.captcha,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.captcha) = $event)),
                ref: "captchaRef",
                onVerify: _cache[2] || (_cache[2] = ($event: any) => ($setup.saveCaptcha($event, _ctx.setErrors)))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DInput, {
                name: "contactLastName",
                id: "contactLastName",
                size: ['md', 'md-xl'],
                rules: 'required',
                "input-label": $setup.labels.contactLastName,
                modelValue: $setup.form.contactLastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.contactLastName) = $event))
              }, null, 8, ["input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_DPhone, {
                name: "contactPhone",
                id: "phone",
                size: ['md', 'md-xl'],
                type: "number",
                "input-label": $setup.labels.contactPhone,
                "default-country-code": $setup.currentCountryCode,
                "no-example": true,
                rules: `required|realPhone:${$setup.form.contactPhone}`,
                modelValue: $setup.form.contactPhone,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.contactPhone) = $event))
              }, null, 8, ["input-label", "default-country-code", "rules", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_DInput, {
                name: "contactEmail",
                id: "contactEmail",
                type: "email",
                size: ['md', 'md-xl'],
                rules: 'required|email',
                "input-label": $setup.labels.contactEmail,
                modelValue: $setup.form.contactEmail,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.contactEmail) = $event))
              }, null, 8, ["input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DInput, {
                name: "confirmContactEmail",
                id: "confirmContactEmail",
                type: "email",
                size: ['md', 'md-xl'],
                rules: 'confirmed:@contactEmail',
                "input-label": $setup.labels.confirmContactEmail,
                modelValue: $setup.form.confirmContactEmail,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.confirmContactEmail) = $event))
              }, null, 8, ["input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DSelect, {
                rules: 'required',
                name: "positionInCompany",
                id: "positionInCompany",
                "label-by": 'title',
                "track-by": 'id',
                "allow-empty": false,
                "input-label": $setup.labels.companyPositions,
                modelValue: $setup.form.positionInCompany,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.form.positionInCompany) = $event)),
                options: $setup.companyPositionsList
              }, null, 8, ["input-label", "modelValue", "options"])
            ]),
            _createVNode(_component_VeeError, { name: "commercialOffer" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(message), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_VeeError, { name: "commercialOfferId" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(message), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_VeeError, { name: "offerId" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(message), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(message), 1)
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_VeeField, {
                    name: "agreeTerms",
                    rules: "required",
                    id: "agreeTerms",
                    type: "checkbox",
                    "checked-value": true,
                    "unchecked-value": false,
                    value: true,
                    modelValue: $setup.form.agreeTerms,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.form.agreeTerms) = $event)),
                    as: "control-checkbox"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, [
                    _createTextVNode(_toDisplayString(_ctx.$t('I agree to the ESKA')) + " ", 1),
                    _createElementVNode("a", {
                      class: "sk-link sk-link--accent sk-link--appearance_underline sk-link--size_xs sk-link--inline",
                      href: $setup.userAgreement,
                      target: "_blank"
                    }, _toDisplayString(_ctx.$t('Terms of Service')), 9, _hoisted_21),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('and')) + " ", 1),
                    _createElementVNode("a", {
                      class: "sk-link sk-link--accent sk-link--appearance_underline sk-link--size_xs sk-link--inline",
                      href: $setup.privacyPolicy,
                      target: "_blank"
                    }, _toDisplayString(_ctx.$t('Privacy Policy')), 9, _hoisted_22)
                  ])
                ]),
                _createVNode(_component_VeeError, { name: "agreeTerms" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, _toDisplayString(message), 1)
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_VeeField, {
                    name: "agreePromotional",
                    id: "agreePromotional",
                    type: "checkbox",
                    "checked-value": true,
                    "unchecked-value": false,
                    value: true,
                    modelValue: $setup.form.agreePromotional,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.form.agreePromotional) = $event)),
                    as: "control-checkbox"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("p", _hoisted_29, [
                    _createTextVNode(_toDisplayString(_ctx.$t('I agree to receive promotional materials and more as outlined in the')) + " ", 1),
                    _createElementVNode("a", {
                      class: "sk-link sk-link--accent sk-link--appearance_underline sk-link--size_xs sk-link--inline",
                      href: $setup.privacyPolicy,
                      target: "_blank"
                    }, _toDisplayString(_ctx.$t('Privacy Policy')), 9, _hoisted_30)
                  ])
                ]),
                _createVNode(_component_VeeError, { name: "agreePromotional" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, _toDisplayString(message), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_VeeError, { name: "nonFieldError" }, {
                  default: _withCtx(({ message }) => [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, _toDisplayString(message), 1)
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                (!meta.valid && Object.keys(errors).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t("Please, fix an error in form")), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_ControlButton, {
                    view: "full",
                    styling: 'primary',
                    disabled: isSubmitting || $setup.isLoad,
                    type: "submit",
                    onClick: _withModifiers(($event: any) => ($setup.validateForm(validate, $setup.getCaptcha)), ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Next')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ])
            ])
          ])
        ]),
        _: 2
      }, 1032, ["loading"])
    ]),
    _: 1
  }, 512))
}