import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "control-descriptor"
}
const _hoisted_3 = { class: "control-descriptor__label control-descriptor__label--static control-descriptor__label--styling_default" }
const _hoisted_4 = { class: "control-descriptor__element" }
const _hoisted_5 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-md g-cols--3-mac main-section-btn-wrap"
}
const _hoisted_6 = { class: "g-row g-row--appearance_nowrap" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_10 = ["href", "aria-label"]
const _hoisted_11 = { class: "control-button__body" }
const _hoisted_12 = { class: "control-button__element control-button__element--attachment_prepend" }
const _hoisted_13 = {
  class: "ds-inliner ds-inliner--size_xl",
  "aria-hidden": "true"
}
const _hoisted_14 = { class: "ds-inliner__body" }
const _hoisted_15 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_i_search = _resolveComponent("i-search")!
  const _component_loading_wrapper = _resolveComponent("loading-wrapper")!

  return (_openBlock(), _createBlock(_component_loading_wrapper, {
    loading: $setup.loading && $setup.isInitialized || $setup.loadingAvailable && $setup.isInitializedAvailable
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["g-row g-row--appearance_spaced g-row--space_lg main-filters-content", `is-main-${$setup.activeCategory?.slug}`])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.nodes, (item, key) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["g-cell g-cols g-cols--12", ['brands' == item.props.filter.slug ? 'g-cols--12-sm g-cols--8-md g-cols--6-mac' : 'g-cols--6-sm g-cols--4-md g-cols--3-mac']]),
            key: `${key}_${$setup.lastModifiedNodes}`,
            id: `${key}_${$setup.lastModifiedNodes}`
          }, [
            ('brands' !== item.props.filter.slug)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(item.props.filter.title), 1),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _mergeProps(item.props, {
                      "is-dynamic": false,
                      available: $setup.available && $setup.available[key],
                      modelValue: $setup.value[key],
                      "is-hide-unavailable": false
                    }), null, 16, ["available", "modelValue"]))
                  ])
                ]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _mergeProps({ key: 1 }, item.props, {
                  "is-dynamic": false,
                  available: $setup.available && $setup.available[key],
                  "available-models": $setup.available?.brandModels,
                  modelValue: $setup.value[key],
                  "is-hide-unavailable": true
                }), null, 16, ["available", "available-models", "modelValue"]))
          ], 10, _hoisted_1))
        }), 128)),
        ($setup.isInitialized)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    class: "round-button round-button--styling_accent round-button--size_md",
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.clearFilters())),
                    "aria-label": _ctx.$t('Reset filters')
                  }, [
                    _createVNode(_component_AppIcon, {
                      name: "i-refresh",
                      "aria-hidden": "true",
                      size: "lg"
                    })
                  ], 8, _hoisted_8)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("a", {
                    class: "control-button control-button--view_full control-button--styling_primary control-button--size_md",
                    href: $setup.catalogUrl,
                    "aria-label": _ctx.$t('Go to result')
                  }, [
                    _createElementVNode("span", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, [
                            _createVNode(_component_i_search)
                          ])
                        ])
                      ]),
                      _createElementVNode("span", {
                        class: "control-button__element control-button__element--primary",
                        textContent: _toDisplayString($setup.searchResultBtnText)
                      }, null, 8, _hoisted_15)
                    ])
                  ], 8, _hoisted_10)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["loading"]))
}