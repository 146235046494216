import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { CURRENT_LOCALE } from './const'

declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
  }
}

const messages = {
  [window.language]: (window.django && window.django.catalog) || {},
}

type compileMessages = {
  locale: string
  messages: typeof messages
}

export const compileMessages = (): compileMessages => ({
  locale: window.language,
  messages,
})

// Number formats
function tryIntlNumberFormatWithUnit(unit = 'kilogram') {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'unit',
      unit,
    })
  } catch (e) {
    if (e.constructor !== RangeError) {
      throw e
    }
    return null
  }
}

const SHORT = { notation: 'compact', compactDisplay: 'short' }
const SHORT_UNIT = {
  ...SHORT,
  unitDisplay: 'short',
  style: tryIntlNumberFormatWithUnit ? 'unit' : 'decimal',
}
const u = (unit) => ({
  ...SHORT_UNIT,
  unit: tryIntlNumberFormatWithUnit ? unit : null,
})

const numberFormats = {
  [window.language]: {
    // liter: u('liter'),
    // short: SHORT,
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    // kilogram: u('kilogram'),
    // degree: u('degree'),
    // kilometer: u('kilometer'),
  },
}

// Pluralization for Slavic languages
// function customRule(choice, choicesLength, orgRule) {
//   if (choice === 0) {
//     return 0
//   }

//   const teen = choice > 10 && choice < 20
//   const endsWithOne = choice % 10 === 1
//   if (!teen && endsWithOne) {
//     return 1
//   }
//   if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
//     return 2
//   }

//   return choicesLength < 4 ? 2 : 3
// }

// Data-time formats

const NUMERIC = 'numeric' as const
const LONG = 'long' as const
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
}
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit' as const,
}
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
}
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
}

const datetimeFormats = {
  [CURRENT_LOCALE]: {
    short: SHORT_DATE_CONFIG,
    medium: { year: NUMERIC, month: LONG, day: NUMERIC },
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    year: { year: NUMERIC },
    datetime: DATETIME_CONFIG,
  },
}

const i18nOptions = {
  ...compileMessages(),
  // pluralizationRules: {
  //   ru: customRule
  // },
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  datetimeFormats,
  numberFormats,
}

export const i18n = createI18n(i18nOptions)

export default {
  install(app: App<Element>): void {
    app.use(i18n)
  },
}
