import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import { catalogApi } from '@/services/catalog.service'

const PROFILE_PREFIX = 'cabinet:offer'
const p = (name: string) => PROFILE_PREFIX + ':' + name

export const OFFERS_CREATE = p('create')
export const OFFERS_EDIT = p('edit')
export const OFFERS_LIST = p('list')

const Create = () => import('./views/Create.vue')
const Edit = () => import('./views/Edit.vue')
const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, OFFERS_LIST, {
      meta: {
        title: () => $t('My offers'),
      },
    }),
    route('create/', Create, OFFERS_CREATE, {
      meta: {
        title: () => $t('Create offer'),
        fallbackRouteName: OFFERS_LIST,
      },
    }),

    receiveRoute(catalogApi.offers.owned.detail, [
      route('edit/', Edit, OFFERS_EDIT, {
        // meta: accessPermission(APPLICATIONS_VIEW, options),
        meta: {
          title: ({ id }) => $t('Edit offer') + ' #' + id,
          fallbackRouteName: OFFERS_LIST,
        },
      }),
      { path: '', redirect: { name: OFFERS_EDIT } },
    ]),
    { path: '', redirect: { name: OFFERS_LIST } },
  ]
}
