import { App, defineAsyncComponent } from 'vue'

const LocalizationWrapper = defineAsyncComponent(() => import('./LocalizationWrapper.vue'))
const ProductMainPreview = defineAsyncComponent(() => import('./ProductMainPreview.vue'))
const ProductInModalPreview = defineAsyncComponent(() => import('./ProductInModalPreview.vue'))
const ShareElement = defineAsyncComponent(() => import('./ShareElement.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('LocalizationWrapper', LocalizationWrapper)
      .component('ProductMainPreview', ProductMainPreview)
      .component('ProductInModalPreview', ProductInModalPreview)
      .component('ShareElement', ShareElement)
    }
}
