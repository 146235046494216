import type { RouteRecordRaw } from 'vue-router'
import { RouterView } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { FinancingApplicationStatusGroup } from '@/const'

const FINANCE_APPLICATION_PREFIX = 'cabinet:financing-application'
const p = (name: string) => FINANCE_APPLICATION_PREFIX + ':' + name

export const FINANCE_APPLICATION_LIST = p('list')
export const FINANCE_APPLICATION_LIST_GROUP = p('list:group')

const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', RouterView, FINANCE_APPLICATION_LIST, {
      redirect: {
        name: FINANCE_APPLICATION_LIST_GROUP,
        params: { group: FinancingApplicationStatusGroup.creditDecision },
      },
      children: [
        route(':group/', List, FINANCE_APPLICATION_LIST_GROUP, {
          meta: {
            title: () => $t('My financing applications'),
          },
        }),
      ],
    }),

    { path: '', redirect: { name: FINANCE_APPLICATION_LIST } },
  ]
}
