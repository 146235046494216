import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import SearchView from './SearchView.vue'
const SearchResultView = defineAsyncComponent(() => import('./SearchResultView.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('SearchView', SearchView)
      .component('SearchResultView', SearchResultView)
  }
}

