import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--space_none" }
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_3 = { class: "sk-space_pb sk-space_pb--2" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--6 sk-space_pr sk-space_pr--2" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--6 sk-space_pl sk-space_pl--2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSlider = _resolveComponent("VueSlider")!
  const _component_ControlInput = _resolveComponent("ControlInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.config?.showSlider)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_VueSlider, _mergeProps({ ...$setup.defaultSliderOptions }, {
              silent: true,
              "model-value": [$setup.internal.min, $setup.internal.max],
              "onUpdate:modelValue": $setup.handleUpdate,
              min: $setup.min,
              max: $setup.max,
              lazy: false
            }), null, 16, ["model-value", "min", "max"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ControlInput, {
        name: `${$props.filter.slug}-min`,
        size: ['md'],
        styling: "accent",
        type: "number",
        value: $setup.internal.min,
        onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.debounceInput([$event.target.value, $setup.internal.max]))),
        min: $setup.min,
        max: $setup.max
      }, null, 8, ["name", "value", "min", "max"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ControlInput, {
        name: `${$props.filter.slug}-max`,
        size: ['md'],
        styling: "accent",
        type: "number",
        value: $setup.internal.max,
        onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.debounceInput([$setup.internal.min, $event.target.value]))),
        min: $setup.min,
        max: $setup.max
      }, null, 8, ["name", "value", "min", "max"])
    ])
  ]))
}