import { defineAsyncComponent } from 'vue'
import type { WidgetsMap } from '@aspectus/vue-filters-generator'

const CheckboxWidget = defineAsyncComponent(
  () => import('./CheckboxWidget.vue')
)
const DataRangeWidget = defineAsyncComponent(
  () => import('./DateRangeWidget.vue')
)
const InputRangeWidget = defineAsyncComponent(
  () => import('./InputRangeWidget.vue')
)
const RangeWidget = defineAsyncComponent(() => import('./RangeWidget.vue'))
const BrandsModelsWidget = defineAsyncComponent(
  () => import('./BrandsModelsWidget.vue')
)

// const defaultMultiselectConfig = {
//   multiple: true,
//   trackBy: 'id',
//   label: 'caption',
//   showLabels: false,
//   closeOnSelect: true,
//   searchable: false,
// }

export const defaultDatePickerConfig = {
  clearable: false,
  enableTimePicker: false,
  ignoreTimeValidation: true,
  'date-format': 'year',
}

export const WIDGETS_MAP: WidgetsMap = {
  'brands-models': {
    component: BrandsModelsWidget,
    config: {
      canAddMore: true,
    },
    // config: {...defaultMultiselectConfig},
  },
  multiselect: {
    component: CheckboxWidget,
    // config: {...defaultMultiselectConfig},
  },
  select: {
    component: CheckboxWidget,
    // config: {...defaultMultiselectConfig},
  },
  'input-range': {
    component: InputRangeWidget,
    // config: {...defaultMultiselectConfig},
  },
  range: {
    component: RangeWidget,
    config: {
      showSlider: true,
    },
  },
  // 'select': {
  //   component: SelectWidget,
  //   config: {
  //     ...defaultMultiselectConfig,
  //     multiple: false,
  //   },
  // },
  // 'input': {
  //   component: InputWidget,
  //   config: {},
  // },
  // 'decimal-range': {
  //   component: SliderWidget,
  //   config: {},
  // },
  'date-range': {
    component: DataRangeWidget,
    config: {
      ...defaultDatePickerConfig,
      // minDate: new Date(),
    },
  },
}
