/* eslint-disable no-restricted-globals */
import { omit } from 'ramda'
import { computed, defineComponent, ref, type Ref, onMounted, watch } from 'vue'

import { useRoute } from 'vue-router'

import { urlGetter } from './utils'
import { Pagination } from '@/const'

const QUERY_PARAMS = ['sort', 'search', 'search_id', 'page']

const same = (x: any) => x
export default defineComponent({
  name: 'url-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({ filters: {} }),
    },
    category: String,
    owner: String,
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    initialPagination: {
      type: Object,
    },
    baseUrl: String,
    dropPageUrl: {
      type: Boolean,
    },
  },
  setup(props, { slots }) {
    const route = useRoute()

    const pagination = ref(props.initialPagination)
    const parameters: Ref<
      { filters?: Record<string, any> } & Partial<Pagination>
    > = ref(props.initial)
    const friendlyUrl = ref<{
      filters: Record<string, unknown>
      [key: string]: unknown
    }>({
      filters: {},
    })
    const urlWithoutPage = ref(props.baseUrl)
    const temp = getTokenStringFromUrl()
    const tokenString = ref(getTokenStringFromUrl())

    const searchString = ref(undefined)

    onMounted(() => {
      window.addEventListener('popstate', () => {
        // parameters.value = props.from({
        //   ...props.initial,
        // })
        
        let path = window.location.pathname
        let page: string = '/1/'
        ;[, path] = path.split(props.baseUrl)
        ;[path, page = '/1/'] = path.split('page')
        ;[,page = '1'] = page.split('/')
        const offset = Number(page) - 1 ? props.initialPagination?.limit * (Number(page) - 1) : 0
        pagination.value = { ...pagination.value, offset }

        tokenString.value = getTokenStringFromUrl()
      })
      parameters.value = props.from({
        ...props.initial,
      })
    })

    watch(
      () => route.path,
      () => {
        parameters.value = props.from({
          ...props.initial,
        })
      }
    )
    function getTokenStringFromUrl() {
      let path = window.location.pathname
      ;[, path] = path.split(props.baseUrl)
      ;[path] = path.split('page')
      if (props.owner) {
        return path
          ? `/${props.category}/owner=${props.owner}/${path}/`
          : `/${props.category}/owner=${props.owner}/`
      }
      return path ? `/${props.category}/${path}/` : `/${props.category}/`
    }

    function initializeToken(params: any) {
      const query: { [x: string]: unknown; filters: Record<string, unknown> } =
        props.to(params)
      const zero = 0
      friendlyUrl.value = query || { filters: {} }
      const keys = Object.keys(friendlyUrl.value)

      const order = {
        path: [
          'prefix',
          'locationCountries',
          'categories',
          'brands',
          'brand_models',
          'brands_models',
          'filters',
          'page',
        ],
        query: ['sort'],
      }
      urlWithoutPage.value = urlGetter(
        [props.baseUrl],
        order,
        omit(QUERY_PARAMS, friendlyUrl.value)
      )
      tokenString.value = urlGetter(
        [`/${props.category}/`],
        { path: order.path },
        {
          ...friendlyUrl.value,
          filters: {
            ...(friendlyUrl.value?.filters || {}),
            owner: props?.owner,
          },
          page: undefined,
        }
      )

      searchString.value = urlGetter([`/${props.category}/`], order, {
        ...friendlyUrl.value,
        owner: props?.owner,
        page: undefined,
      })
      const shift = 1
      const currentPage =
        Math.floor(Number(params.offset) / Number(params.limit)) + shift
      const page = currentPage > shift ? currentPage : null
      // const base = Object.keys(params.filters).length ? `${this.baseUrl}filters` : this.baseUrl;
      // const fullUrl = urlGetter([base], order, { ...params, page });
      const fullUrl = urlGetter(
        // Object.keys(params.filters || {}).length
        //   ? [props.baseUrl, 'filters']
        //   : [props.baseUrl],
        [props.baseUrl],
        order,
        { ...friendlyUrl.value, page }
      )
      return fullUrl
    }

    function updateUrl(
      params: { filters: Record<string, any> } & Partial<Pagination>
    ) {
      const fullUrl = initializeToken(params)

      history.pushState({ url: fullUrl }, '', `${fullUrl}`)
    }

    function changeParameters(
      value: { filters: Record<string, any> } & Partial<Pagination>
    ) {
      parameters.value = value
      updateUrl(
        parameters.value as {
          filters: Record<string, any>
        } & Partial<Pagination>
      )
    }

    return () =>
      slots.default?.({
        parameters: parameters.value,
        changeParameters,
        pagination: pagination.value,
        updateUrl,
        tokenString: tokenString.value,
        urlWithoutPage: urlWithoutPage.value,
      })
  },
})
