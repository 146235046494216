import { App, defineAsyncComponent } from 'vue'

const Login = defineAsyncComponent(() => import('@m/auth/components/Login.vue'))
const SignUp = defineAsyncComponent(() => import('@m/auth/components/SignUp.vue'))
const SetNewPassword = defineAsyncComponent(() => import('@m/auth/components/SetNewPassword.vue'))
const PasswordReset = defineAsyncComponent(() => import('@m/auth/components/PasswordReset.vue'))
const AuthProvider = defineAsyncComponent(() => import('@m/auth/components/AuthProvider.vue'))
const UserAccessState = defineAsyncComponent(() => import('@m/auth/components/UserAccessState.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('Login', Login)
      .component('SignUp', SignUp)
      .component('SetNewPassword', SetNewPassword)
      .component('PasswordReset', PasswordReset)
      .component('AuthProvider', AuthProvider)
      .component('UserAccessState', UserAccessState)
  }
}
