import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const FavoritesView = defineAsyncComponent(() => import('./FavoritesView.vue'))
const FavoriteToggler = defineAsyncComponent(() => import('./FavoriteToggler.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('FavoritesView', FavoritesView)
      .component('FavoriteToggler', FavoriteToggler)
  }
}

