import { r, createSender } from '@/plugins/resource/resource'
import { baseResource } from '@/plugins/resource'
import { prefixLanguage } from "@/utils/urls"

import { friendlyUrlGenerator } from "@/packages/vue-friendly"

const MODEL = '/vacancies/catalog/'

const FILTERS_LIST_URL_POSTFIX = 'ajax/'

const order = {
  path: ['prefix', 'direction', 'position', 'country', 'city', 'work_location', 'employment_type', 'postfix', 'filters'],
  query: ['pagination_offset'],
}

export const ajaxVacancyList = friendlyUrlGenerator(
  [prefixLanguage(MODEL), FILTERS_LIST_URL_POSTFIX],
  baseResource,
  order
)

export const vacanciesApi = {
  responseVacancy: r('/create/', 'response-vacancy', createSender),
  requestVacancy: r('/create/', 'request-vacancy', createSender),
}
