interface UserInfo {
  info: {
    userType: string
  }
  isAuthenticated: () => boolean
}

import { curry } from 'ramda'
import { Or } from '@aspectus/permissions'
import { accessConfig } from '@/packages/vue-router-permissions'
import { simpleChecker } from '../auth/permissions'
import { ACCESS_TOKENS, IS_AGENT, IS_DEALER, IS_MANAGER } from './accessTokens'
import Route403 from '@m/cabinet/views/Route403.vue'


const simpleCheckPermission = (checker: unknown) => simpleChecker(checker)

export const isType = curry(
  (type: string, user: UserInfo) => user.info.userType === type
)
export const isPermittedSimple = (permissions: string[]) =>
  !!(
    ACCESS_TOKENS &&
    permissions.every((permission) => ACCESS_TOKENS.includes(permission))
  )
export const isPermitted = curry(
  (permissions: string[]) =>
    !!(
      ACCESS_TOKENS &&
      permissions.every((permission) => ACCESS_TOKENS.includes(permission))
    )
)

export const toBeNotAuthenticated = simpleCheckPermission(
  (user: UserInfo) => !user.isAuthenticated()
)

export const toHasOwnPermission = (permissions: string[]) =>
  simpleCheckPermission(() => isPermitted(permissions))

export const toHasPermission = (permissions: string[]) => {
  return new Or(toHasOwnPermission(permissions))
}

export const accessPermission = (
  permissions: string[],
  options: { [key: string]: unknown }
): { [key: string]: unknown } => {
  return {
    accessConfig: accessConfig.apply(this, [
      toHasPermission(permissions),
      // { resolver: Route403 },
      { 
        name: 'cabinet:no-access'

      },
      options,
    ]),
  }
}
