import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[_ctx.name] || !$setup.isValid, success: $props.isSuccess }])
  }, [
    _createVNode($setup["VueMultiselect"], _mergeProps({ class: "d-control-descriptor__element" }, _ctx.$attrs, {
      options: $props.options,
      label: $props.labelBy,
      "track-by": $props.trackBy,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      "allow-empty": $props.allowEmpty,
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      required: $props.rules,
      value: $setup.model
    }), _createSlots({ _: 2 }, [
      _renderList($setup.eSlots, (name, key) => {
        return {
          name: name,
          fn: _withCtx((binded) => [
            _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
          ])
        }
      }),
      ($props.pagination && Math.ceil($props.pagination.total/$props.pagination.page_size) > $props.pagination.page)
        ? {
            name: "afterList",
            fn: _withCtx(() => [
              _createElementVNode("div", null, [
                _withDirectives(_createElementVNode("div", null, null, 512), [
                  [_directive_observe_visibility, $setup.visibilityChanged]
                ])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["options", "label", "track-by", "placeholder", "disabled", "allow-empty", "modelValue", "required", "value"]),
    _renderSlot(_ctx.$slots, "label")
  ], 2))
}