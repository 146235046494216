import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UrlParametersController = _resolveComponent("UrlParametersController")!

  return (_openBlock(), _createBlock(_component_UrlParametersController, {
    "base-url": $props.baseUrl,
    to: $setup.parametersTo,
    from: $setup.parametersFrom
  }, {
    default: _withCtx(({ parameters, changeParameters, urlWithoutPage }) => [
      _createVNode($setup["CatalogController"], {
        ref: "controller",
        "catalog-filters-component": $props.catalogFiltersComponent,
        "url-without-page": urlWithoutPage.value,
        "initial-parameters": parameters.value,
        "initial-pagination": $props.pagination,
        "filters-list": $props.filters,
        page: parameters.value?.page,
        resource: $props.resource,
        "onUpdate:parameters": changeParameters
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, 1032, ["catalog-filters-component", "url-without-page", "initial-parameters", "initial-pagination", "filters-list", "page", "resource", "onUpdate:parameters"])
    ]),
    _: 3
  }, 8, ["base-url"]))
}