import { defineStore } from 'pinia'
import { ref } from 'vue'
import { comparisonsApi } from '@/services/comparisons.service'

export const useComparisonsStore = defineStore('comparisonsStore', () => {

  const count = ref<number>(0)

  function getCount() {
    comparisonsApi.counter.execute().then(({ item }) => {
      count.value = item.count
    })
  }

  return {
    count,
    getCount,
  }
})
