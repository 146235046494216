import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'

export const FAVORITES_LIST = 'cabinet:favorites:list'

const List = () => import('./views/List.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('list/', List, FAVORITES_LIST, {
      meta: {
        title: () => $t('Favorite offers'),
      },
    }),
    { path: '', redirect: { name: FAVORITES_LIST } },
  ]
}
