import { PropType, watch, ExtractPropTypes, unref } from 'vue'
import { equals } from 'ramda'
import {
  baseResource,
} from '@aspectus/resource'

import { useLoadable } from '@/packages/vue-loading-state'
import type { Pagination } from '@/const';
// import type { Loadable } from '@/packages/vue-loading-state'


export function makeProps(): ExtractPropTypes<{ resource: typeof baseResource}> {
  return {
    resource: {
      type: Object as PropType<typeof baseResource>,
      required: true,
    },
  }
}

export function useResourceLoader(
  { resource }: { resource: typeof baseResource },
  resultHandler?: (r?: any) => void,
  errorHandler?: (r?: unknown) => void
) {

  function getItems(p: Record<string, unknown>) {
    return unref(resource).execute(p)
      .then((r: Record<string, unknown>) => r)
      .catch(applyError)
  }

  const { result, loading, load } = useLoadable(getItems)

  const applyError = (result: unknown): void => {
    errorHandler && errorHandler(result)
  }

  watch(result as unknown as { items: any[], pagination: Pagination}, (newVal: { items: any[], pagination: Pagination}, oldVal: Record<string, unknown | null>) => {
    if (resultHandler && 'object' == typeof newVal && !equals(newVal, oldVal)) {
      resultHandler(newVal)
    }
  })

  return {
    loading,
    result,
    load,
  }
}
