import { createSender, r } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'comparisons'


export const comparisonsApi = {
  add: r('/add/', MODEL, createSender),
  categoriesList: r('/categories-list/', MODEL),
  counter: r('/counter/', MODEL),
  list: r(q('/list/', ['category_id']), MODEL),
  removeAll: r('/remove-all/', MODEL, createSender),
  remove: r('/remove/', MODEL, createSender),
}
