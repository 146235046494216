import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

import ComparisonModalTrigger from './ComparisonModalTrigger'

const ComparisonsView = defineAsyncComponent(() => import('./ComparisonsView.vue'))
const ComparisonsToggler = defineAsyncComponent(() => import('./ComparisonsToggler.vue'))

export default {
  install(app: App<Element>): void {
    app
      .component('ComparisonsView', ComparisonsView)
      .component('ComparisonsToggler', ComparisonsToggler)
      .component('ComparisonModalTrigger', ComparisonModalTrigger)
  }
}

