import { createSender, r } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'saved-search'

export const savedSearchApi = {
  create: r('/create/', MODEL, createSender),
  delete: r('/delete/', MODEL, createSender),
  getViewLink: r('/get-view-link/', MODEL, createSender),
  list: r(q('/list/'), MODEL),
  update: r('/update{/id}/', MODEL, createSender).config('method', 'PATCH'),
}
