import { pipe, partial } from 'ramda'

import { i18n } from '@/i18n'
import { CURRENT_LOCALE } from '@/const'

const { t: $t, d: $d, n: $n } = i18n.global

export interface WordsMap {
  // zero : string
  one: string
  // two : string
  few: string
  many: string
  // other: string
}

export function pluralization(
  wordsMap: WordsMap,
  count: string | number
): string {
  const pr = new Intl.PluralRules(window.language)
  const intlCount = pr.select(Number(count))

  return `${$n(Number(count))} ${wordsMap[intlCount as keyof WordsMap] || wordsMap.few}`
}

export const fieldPluralizationResolver = (key: string, map) =>
  pipe(key, partial(pluralization, [map]))

function transformDateTime(this: any, config: any, value: any) {
  if (value) {
    return $d(new Date(value), config, CURRENT_LOCALE)
  }
  return $t('-')
}
export const fieldDateResolver = (
  key: string,
  config = 'datetime'
): ((...arg: any[]) => any) => pipe(key, partial(transformDateTime, [config]))

function transformNumber(config, value) {
  return $n(Number(value), config)
}
export const fieldNumberResolver = (
  key: any,
  config = null
): ((...arg: any[]) => any) => pipe(key, partial(transformNumber, [config]))
