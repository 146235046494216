import { r, createSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

const MODEL = 'financing-app'

export const financingApplicationsApi = {
  counters: r('/counters/', MODEL),
  applyingForFinancing: r('/applying-for-financing-create/', MODEL, createSender),
  applyingForFinancingUpdate: r('/applying-for-financing-update{/id}/', MODEL, createSender).config('method', 'PATCH'),
  checkCompany: r('/check-company/', MODEL, createSender),
  create: r('/create/', MODEL, createSender),
  getBankLink: r('/get-bank-link/', MODEL, createSender),
  retrieve: r('/detail{/id}/', MODEL),
  update: r('/detail{/id}/', MODEL, createSender).config('method', 'PATCH'),
  list: r(q('/list/', ['filters']), MODEL),
  filtersList: r('/filter/list/{?group}', MODEL),
  statusesList: r('/statuses/list/', MODEL),
  positionInCompanyList: r('/position-in-company/list/', MODEL),
  mainBusinessActivityList: r('/main-business-activity/list/', MODEL),
  equipmentStatusList: r('/equipment-status/list/', MODEL),
}
