import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["ds-inliner svg-icon-cover", $setup.inlinerSizes])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.props.name), {
      class: "ds-inliner__body",
      key: $setup.props.name
    }))
  ], 2))
}