import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogController = _resolveComponent("CatalogController")!

  return (_openBlock(), _createBlock($setup["UrlParametersController"], {
    "base-url": $props.baseUrl,
    category: $props.category,
    to: $setup.parametersTo,
    from: $setup.parametersFrom,
    "initial-pagination": $props.pagination
  }, {
    default: _withCtx(({ parameters, changeParameters, pagination, tokenString, urlWithoutPage }) => [
      _createVNode(_component_CatalogController, {
        ref: "controller",
        "url-without-page": urlWithoutPage,
        "token-string": tokenString,
        "initial-parameters": parameters,
        "initial-pagination": pagination,
        "filters-list": $props.filters,
        category: $props.category,
        page: parameters?.page,
        resource: $setup.ajaxCatalogList,
        "onUpdate:parameters": changeParameters,
        "main-attributes-labels": $setup.mainAttributesLabels
      }, {
        default: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
        ]),
        "append-content": _withCtx((bounded) => [
          _renderSlot(_ctx.$slots, "append-content", _normalizeProps(_guardReactiveProps(bounded)))
        ]),
        _: 2
      }, 1032, ["url-without-page", "token-string", "initial-parameters", "initial-pagination", "filters-list", "category", "page", "resource", "onUpdate:parameters"])
    ]),
    _: 3
  }, 8, ["base-url", "category", "initial-pagination"]))
}